import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/service/base.service';
import { IChallengeProgram } from './desafios.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DesafiosService extends BaseService {

  constructor(public http: HttpClient) { super(http); }

  public getChallengeProgram() {
    return this.http.get<IChallengeProgram[]>(environment.environmentEndpointUrl()+"api/Home/challenge-sunburst"
    ).toPromise();

 }

}
