import { DOCUMENT } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, Inject } from '@angular/core';

@Component({
  selector: 'app-modal-img',
  templateUrl: './modal-img.component.html',
  styleUrls: ['./modal-img.component.css']
})
export class ModalImgComponent implements OnInit {
  @Input() src: string;
  @Output() closeModal = new EventEmitter();

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.document.body.style.overflowY = 'hidden';
  }

  ngOnDestroy(): void {
    this.document.body.style.overflowY = 'visible';
  }

  close(){
    this.closeModal.emit(false);
  }

}
