import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { BasePageComponent } from 'src/app/pages/base-page/base-page.component';
import { MenuTranslateService } from 'src/app/pages/nav-menu/components/menu-translate/menu-translate.service';
import { IEsgCards, IFiltros } from './esg.model';
import { EsgService } from './esg.service';

@Component({
  selector: 'app-esg',
  templateUrl: './esg.component.html',
  styleUrls: ['./esg.component.scss']
})
export class EsgComponent extends BasePageComponent{
  @Output() isShowEsg = new EventEmitter<boolean>();

  borderOne = false;
  borderTwo = false;
  borderThree = false;
  selectedBorderOne = false;
  selectedBorderTwo = false;
  selectedBorderThree = false;
  isShow = false;
  viewHeight: number;

  sum = 15;
  throttle = 50;
  scrollDistance = 2;
  direction = "";
  totalItens = 0;

  data: IEsgCards;
  itens: IEsgCards;
  filter: IFiltros;
  sections = {
    environment: false,
    social:false,
    governance: false
  }
  esgIdFilter: Set<string> = new Set<string>();

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public translate: TranslateService,
    public modal: NgbModal,
    public esgService: EsgService,
    public menuTranslateService: MenuTranslateService
    ) {
      super(router, route, messageService, translate, modal);
     }

  onInit() {
    this.startLoading();
    this.loadData();
    this.menuTranslateService.getLanguage().subscribe(()=> {
      this.loadData();
    });
    this.viewHeight = window.innerHeight - document.getElementById('navHeader')?.clientHeight || 605;
  }

  loadData(){
    this.esgService.getInfoFilters().then(async (filter: IFiltros) => {
      this.filter = filter;
      this.esgService.getCards(1, this.sum, this.esgIdFilter).then(async (data: IEsgCards) =>{
        this.data = data;
        this.itens = Object.assign({}, this.data);
        this.totalItens = data.totalItens.Count;
      }).finally(()=>{
        this.stopLoading();
      });
    });
  }

  clickButtonImg(section: string, esgId: number) {
    this.isShow=true;
    this.sections = {
      environment: false,
      social:false,
      governance: false
    }
    this.esgIdFilter = new Set<string>();
    this.ApplyFilterCard(section, esgId);
    this.scroll('esg-data');
  }

  ApplyFilterCard(section:string, esgId:number){
    this.sum = 15;
    this.data = undefined;
    this.itens = undefined;
    this.totalItens = 0;
    this.sections[section] = !this.sections[section];
    this.esgIdFilter.has(esgId.toString()) ? this.esgIdFilter.delete(esgId.toString()) : this.esgIdFilter.add(esgId.toString());
    this.loadData();
    this.colorButtonSelected();
    this.isShowEsg.emit(this.isShow);
  }

  selectOption(options, sections){
    let result = false;
    options.map(o => {
      if(sections.indexOf(o) != -1){
        result = true
      }
    })
    return result;
  }

  sectionSelected(){
    const sections = [];
    return new Promise((resolve,reject) => {
      for (const key in this.sections) {
        if(this.sections[key]){
          sections.push(key)
        }
      }
      resolve(sections);
    });
  }

  colorButtonSelected() {
    this.selectedBorderOne = this.sections.environment;
    this.selectedBorderTwo = this.sections.social;
    this.selectedBorderThree = this.sections.governance;
  }

  colorButton(value) {
    if (value === 1)
      this.borderOne = true;
      else if (value === 2)
      this.borderTwo= true;
      else if (value === 3)
      this.borderThree=true;
    }

  colorLeave(value) {
    if (value === 1)
    this.borderOne = false;
    else if (value === 2)
    this.borderTwo = false;
    else if (value === 3)
    this.borderThree = false;
  }

  addItems(startIndex, endIndex, _method) {
    this.esgService.getCards(startIndex, endIndex, this.esgIdFilter)
    .then(async (data: IEsgCards) => {
      if(this.totalItens === data.totalItens.Count){
        (this.itens.items).push(...data.items);
      } else {
        this.sum = 15;
        this.data = undefined;
        this.loadData();
      }
    });
  }

  appendItems(startIndex, endIndex) {
    this.addItems(startIndex, endIndex, "push");
  }

  prependItems(startIndex, endIndex) {
    this.addItems(startIndex, endIndex, "unshift");
  }

  onScrollDown() {
    // add another 15 items
    const start = this.sum + 1;
    if(start < this.totalItens) {
      this.sum += 16;
      this.appendItems(start, this.sum);
      this.direction = "down";
    }
  }

  async scroll(line: string) {
    if(this.isShow) {
      const componentRect = document.getElementById(line).getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();
      window.scroll(0,componentRect.top - bodyRect.top - 60);
    }
  }

  delay(ms: number) {
    return new Promise( resolve => {
      setTimeout(resolve, ms)
    });
  }

  expandir(){
    this.isShow = !this.isShow;
    this.isShowEsg.emit(this.isShow);
  }

}
