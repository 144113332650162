<div class="main-global">
  <div *ngIf="this.data !==null" class="container-fluid main-content p-0 elementToFadeIn"  [style]="'height:'+viewHeight+'px;'">
    <div class="col-12 h-100 d-flex flex-row p-0">
      <div class="col-6 h-100 p-0">
        <div class="left-content">
          <span class="yellow-content">
            {{"HOME.HOME_SUBSCRIBED.TITLE_ESG" | translate}}
          </span>
          <div class="section-content">
            <span class="section-title">
              ESG
            </span>
            <p>
              {{"HOME.HOME_SUBSCRIBED.PARAGRAPH_ESG" | translate}} <br/>
              {{"HOME.HOME_SUBSCRIBED.PARAGRAPH_ESG_" | translate}}
            </p>
            <div class="button-more-esg">
              <a class="button bold mt-6" (click)="expandir(); scroll('esg-data')">
                {{"HOME.HOME_SUBSCRIBED.NAME_BUTTON" | translate}}
               <span></span><span></span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ul>
        <li class="slide slide-1">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <span class="section-title">
              {{"HOME.HOME_SUBSCRIBED.TITLE_ENVIROMENT" | translate}}
            </span>
            <span class="section-subtitle">
              enviroment
            </span>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center">
            <span class="section-footer mb-1">
              {{"HOME.HOME_SUBSCRIBED.PARAGRAPH_ESG-ENVIROMENT_1" | translate}}
            </span>
            <span class="section-footer">
              {{"HOME.HOME_SUBSCRIBED.PARAGRAPH_ESG-ENVIROMENT_2" | translate}}
            </span>
            <div class="buttons-more">
              <a class="button bold mt-6" (click)="clickButtonImg('environment', filter?.infos.environmentId)">
                {{"HOME.HOME_SUBSCRIBED.NAME_BUTTON" | translate}}
               <span></span><span></span>
              </a>
            </div>
          </div>
        </li>
        <li class="slide slide-2">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <span class="section-title">
              Social
            </span>
            <span class="section-subtitle">
              social
            </span>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center">
            <span class="section-footer mb-1">
              {{"HOME.HOME_SUBSCRIBED.PARAGRAPH_ESG-SOCIAL_1" | translate}}
            </span>
            <span class="section-footer">
              {{"HOME.HOME_SUBSCRIBED.PARAGRAPH_ESG-SOCIAL_2" | translate}}
            </span>
            <div class="buttons-more">
              <a class="button bold mt-6" (click)="clickButtonImg('social', filter?.infos.socialId)">
                {{"HOME.HOME_SUBSCRIBED.NAME_BUTTON" | translate}}
                <span></span><span></span>
            </a>
          </div>
          </div>
        </li>
        <li class="slide slide-3">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <span class="section-title">
              {{"HOME.HOME_SUBSCRIBED.TITLE_GOVERNANCE" | translate}}
            </span>
            <span class="section-subtitle">
              governance
            </span>
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center">
            <span class="section-footer mb-1">
              {{"HOME.HOME_SUBSCRIBED.PARAGRAPH_ESG-GOVERNANCE_1" | translate}}
            </span>
            <span class="section-footer">
              {{"HOME.HOME_SUBSCRIBED.PARAGRAPH_ESG-GOVERNANCE_2" | translate}}
            </span>
            <div class="buttons-more">
              <a class="button bold mt-6" (click)="clickButtonImg('governance', filter?.infos.governanceId)">
                {{"HOME.HOME_SUBSCRIBED.NAME_BUTTON" | translate}}
                <span></span><span></span>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div id="esg-data"></div>
  <div  *ngIf="isShow" class="data-height"  [style]="'height:'+viewHeight+'px;'">
    <div class="buttons-card">
      <div class="button1">
        <div class="bordaButton1" [ngClass]="{'colorButton': borderOne || selectedBorderOne}"></div>
        <button class="bigNumber" (click)="ApplyFilterCard('environment', filter?.infos.environmentId)" (mouseover)="colorButton(1)"
          (mouseleave)="colorLeave(1)" [class.selecionado]="sections.environment">
          <img class="icone" src="assets/images/esg-icons/environment.svg" alt="enviroment">
          {{filter.infos.environment}}
        </button>
        <h6 class="paragraphBG">{{"HOME.HOME_SUBSCRIBED.TITLE_ENVIROMENT_2" | translate}}</h6>
        <div class="bordaButton2" [ngClass]="{'colorButton2': borderOne || selectedBorderOne}"></div>
      </div>
      <div class="button2">
        <div class="bordaButton1" [ngClass]="{'colorButton': borderTwo || selectedBorderTwo}"></div>
        <button class="bigNumber" (click)="ApplyFilterCard('social', filter?.infos.socialId)" (mouseover)="colorButton(2)"
          (mouseleave)="colorLeave(2)" [class.selecionado]="sections.social">
          <img class="icone" src="assets/images/esg-icons/social.svg" alt="social">
          {{filter?.infos.social}}
        </button>
        <h6 class="paragraphBG">{{"HOME.HOME_SUBSCRIBED.TITLE_SOCIAL_2" | translate}}</h6>
        <div class="bordaButton2" [ngClass]="{'colorButton2': borderTwo || selectedBorderTwo}"></div>
      </div>
      <div class="button3">
        <div class="bordaButton1" [ngClass]="{'colorButton': borderThree || selectedBorderThree}"></div>
        <button class="bigNumber" (click)="ApplyFilterCard('governance', filter?.infos.governanceId)" (mouseover)="colorButton(3)"
          (mouseleave)="colorLeave(3)"  [class.selecionado]="sections.governance">
          <img class="icone" src="assets/images/esg-icons/governance.svg" alt="governance">
          {{filter?.infos.governance}}
        </button>
        <h6 class="paragraphBG">{{"HOME.HOME_SUBSCRIBED.TITLE_GOVERNANCE_2" | translate}}</h6>
        <div class="bordaButton2" [ngClass]="{'colorButton2': borderThree || selectedBorderThree}"></div>
      </div>
    </div>
    <div class="row container-card"
          id="infinite-scroll" #infiniteScroll
          infinite-scroll
          [infiniteScrollDistance]="scrollDistance"
          [infiniteScrollThrottle]="throttle"
          [scrollWindow]="false"
          (scroll)="onScrollDown()">
      <div *ngFor="let item of itens?.items" class="col-4 card-item">
        <app-card-esg [item]="item"></app-card-esg>
      </div>
      <ng-template #notFound>
        <div class="not-found">
          <div class="p-container" class="box-not">
            <p class="no-content">
              {{"CRUD.MSG.NO_CONTENT" | translate}}
            </p>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="spacer"></div>
  </div>

</div>
