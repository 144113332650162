import { ChallengerFilterService } from './../../service/challenger-filter/challenger-filter.service';
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { ISunburstData } from '../desafios/desafios.model';
import { IDesafioFilter } from '../prova-conceito/prova.model';

declare var require: any;
// let highchartsTreemap = require('highcharts/modules/treemap')
let sunburst = require('highcharts/modules/sunburst')

// highchartsTreemap(Highcharts);
sunburst(Highcharts);


@Component({
  selector: 'app-sunburst-graph',
  templateUrl: './sunburst-graph.component.html',
  styleUrls: ['./sunburst-graph.component.css']
})
export class SunburstGraphComponent implements OnInit, OnChanges  {
  @Input() programa : string = '';
  @Input() sunburst : ISunburstData;
  @Input() sunburstHeight : number;

  public variavelTeste: any;

  public options: any;

  highcharts = Highcharts;
  ngOnChanges(changes: SimpleChanges) {
    if(this.sunburst?.data?.length > 0 && changes.sunburst) {
      this.createChart();
      let titles : HTMLElement[] = this.ref.nativeElement.getElementsByTagName('title');
      Array.from(titles).forEach(function(item, i){
        item.remove();
      })
    }
  }

  constructor(
    public translate: TranslateService,
    public route: Router,
    private ref: ElementRef,
    private challengerFilterService: ChallengerFilterService
  ) {}

  ngOnInit() {
  }

  sunburstClick(e){
    if(Number(e.point.id) >= 3){
        const params: IDesafioFilter = {
          id:e.point.idChallenger,
          challenge: e.point.name,
          program: e.point.program,
          cyclo: e.point.cycle,
          miner: "", startup: "", tematic:""
        };
        this.challengerFilterService.setChallenger(params);
    }
  }

  translateDesafios(keyWord:string){
    let response = '';
    this.translate.get(keyWord).subscribe(res => {
      response = res;
    });
    return response;
  }

  createChart(): void {
    this.options = {
      chart: {
        height: this.sunburstHeight,
        backgroundColor: 'rgb(0,0,0,0)',
      },
      plotOptions: {
        sunburst: {
          borderColor: 'white',
          borderWidth: 1,
          states: {
            hover: {
              enabled: true,
              brightness: -0.1,
              opacity: 1
            }
          },
          drillUpButton: {
            position: {
              x: -40,
              y: 40
            }
          }
        }
      },
      colors: ['transparent', '#c0ffff', '#c0e0ff', '#ceff9d', '#ffce9d', '#c0ffff', '#c0e0ff', '#ceff9d', '#ffce9d', '#c0ffff', '#c0e0ff', '#ceff9d', '#ffce9d', '#c0ffff', '#c0e0ff', '#ceff9d', '#ffce9d', '#c0ffff', '#c0e0ff', '#ceff9d', '#ffce9d', '#c0ffff', '#c0e0ff', '#ceff9d', '#ffce9d', '#c0ffff', '#c0e0ff', '#ceff9d', '#ffce9d', '#c0ffff', '#c0e0ff', '#ceff9d', '#ffce9d', '#c0ffff', '#c0e0ff', '#ceff9d', '#ffce9d', '#c0ffff', '#c0e0ff', '#ceff9d', '#ffce9d', '#c0ffff', '#c0e0ff', '#ceff9d', '#ffce9d', '#c0ffff', '#c0e0ff', '#ceff9d', '#ffce9d', '#c0ffff', '#c0e0ff', '#ceff9d', '#ffce9d', '#c0ffff', '#c0e0ff', '#ceff9d', '#ffce9d', '#c0ffff', '#c0e0ff', '#ceff9d', '#ffce9d', '#c0ffff', '#c0e0ff', '#ceff9d', '#ffce9d'],
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      series: [{
        type: 'sunburst',
        data: this.sunburst.data,
        events: {
          click: (e) => this.sunburstClick(e)
        },
        allowDrillToNode: true,
        cursor: 'pointer',
        dataLabels: {
          format: '{point.name}',
          rotationMode: 'circular',
        },
        levels: [{
          level: 1,
          levelIsConstant: false,
          levelSize: {
            unit: 'percentage',
            value: 18
          },
        }, {
          level: 2,
          levelSize: {
            unit: 'percentage',
            value: 12
          },
          colorByPoint: true,
        },
        {
          level: 3,
          levelSize: {
            unit: 'percentage',
            value: 45,
          }
        }, {
          level: 4,
        }]
      }],
      tooltip: {
        headerFormat: '',
        pointFormat: '<tspan style="font-size: 1rem;">{point.name}.<br>{point.value} '+ this.translateDesafios("CYCLE.PRIORIZED") +'.<br>{point.program}</tspan>'
      },
    };

    Highcharts.chart('container', this.options);
  }
}
