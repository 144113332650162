<div class="card">
  <div class="card-effect" (click)="cardClick()">
    <span></span><span></span>
    <div class="associated-inside-container">
      <div class="associated-image-container">
        <div style="margin: auto;">
          <img *ngIf="associated?.img" class="associated-image" [src]="srcImage(associated?.img)"/>
        </div>
      </div>
      <div class="associated-text-container">
        <div class="associated-detail-text">
          <b class="associated-label-name">{{associated?.name}}</b>
        </div>
        <div class="associated-detail-text">
          <b class="associated-detail-text-bold">{{associated?.challengers | number: '2.0-0'}} &nbsp;</b>
          {{"HOME.HOME_MINER.CHALLENGES" | translate}}
        </div>
        <div class="associated-detail-text">
          <b class="associated-detail-text-bold">{{associated?.pocs | number: '2.0-0'}} &nbsp;</b>
          {{"HOME.HOME_MINER.RAISED_POCS" | translate}}
        </div>
        <div class="associated-detail-text">
          <b class="associated-detail-text-bold">{{associated?.contracteds | number: '2.0-0'}} &nbsp;</b>
          {{"HOME.HOME_MINER.CONTRACTED_STARTUPS" | translate}}
        </div>
      </div>
      <a  [href]="associated?.website" target="_blank">
        <div class="associated">
          <b class="associated-site-label">{{"HOME.HOME_MINER.MINER_WEBSITE" | translate}}</b>
        </div>
      </a>
    </div>
  </div>
</div>
