import { Component, OnInit } from '@angular/core';
import { BasePageRoutingMessageComponent } from '../base-page/base-page-routing-message/base-page-routing-message.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wrong-route',
  templateUrl: '../base-page/base-page-routing-message/base-page-routing-message.component.html',
  styleUrls: ['../base-page/base-page-routing-message/base-page-routing-message.component.css', './wrong-route.component.css']
})
export class WrongRouteComponent extends BasePageRoutingMessageComponent implements OnInit {

  constructor(protected router: Router) {
    super(router);
    super.setClassView("wrong-route");
    this.textView = "APP.WRONG_ROUTE"
    window.location.href = 'https://www.mininghub.com.br/404';
  }

  ngOnInit(): void {
    super.getUrlRoute();

  }

}
