import { Component, Input } from '@angular/core';
import { LoginService } from 'src/app/service/login.service';
import { MenuTranslateService } from './menu-translate.service';

@Component({
  selector: 'app-menu-translate',
  templateUrl: './menu-translate.component.html',
  styleUrls: ['./menu-translate.component.css']
})
export class MenuTranslateComponent {

  @Input() isBodyContainer: true;
  get languages() { return LoginService.getLanguages; }
  get selectedLanguage() { return LoginService.getSelectedLanguageDefault; }

  constructor(
    public loginService: LoginService,
    public menuTranslateService: MenuTranslateService
  ){}

  selectTranslate(language){
    this.menuTranslateService.selectedLang(language);
  }
}
