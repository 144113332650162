import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { LoginService } from 'src/app/service/login.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent {

  constructor(private loginService: LoginService) { }

  @ViewChild('calend') calendar: Calendar;

  private _dateModel: any;
  @Input() get dateModel(): any {
    return this._dateModel;
  }
  set dateModel(value: any) {
    this._dateModel = value;
    this.dateModelChange.emit(this._dateModel);
  }
  @Output() dateModelChange: EventEmitter<any> = new EventEmitter();

  @Input() readOnly = false;

  @Input() range = false;

  @Input() showTime = false;

  @Input() minDate: Date;

  @Input() maxDate: Date;

  @Input() dateField: string;

  @Input() dateId: string

  @Input() showBar: boolean = false;

  ngOnInit() {
  }

  getLocale() {
    return this.loginService.getDatePickerLocale();
  }

  getLanguage(){
   return this.loginService.getLanguageSelectedValue(); 
  }

}
