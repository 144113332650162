<div *ngIf="this.sunburst !== null" class="background elementToFadeIn">
  <div class="main" [style]="'height:'+viewHeight+'px;'">
   <div class="main-sunburst">
      <div class="col-12">
        <div class="row">
          <div class="col-5 align-self">
            <div class="row">
              <div class="d-flex flex-column justify-content-center align-items-start w-100">
                <div>
                  <img class="icon-arrow-right" *ngFor="let n of [].constructor(12);let i = index" src="assets/Icons/arrow_right_blue.svg" alt="">
                </div>
              </div>
              <div class="d-flex flex-column justify-content-start align-items-start w-100">
                <div class="text-left w-100 padding-left">
                  <span class="number icon-more">
                    +
                  </span>
                  <span class="number">
                    {{sunburst?.conections}}
                  </span>
                </div>
                <span class="yellow-content">
                  {{"HOME.HOME_SUBSCRIBED.CONECTION" | translate}}
                </span>
                <div class="text-left w-70 padding-left-2">
                  <span class="yellow-text">
                    {{"HOME.HOME_SUBSCRIBED.PERFORMED" | translate}}
                  </span>
                </div>

                <div class="d-flex flex-row padding-left">
                  <p class="conexoes mt-5">
                    {{"HOME.HOME_SUBSCRIBED.PARAGRAPH_CONECTIONS" | translate}}
                  </p>
                </div>
                <div class="row">
                  <div class="d-flex flex-column justify-content-center align-items-center w-100 pl-1">
                    <div class="pt-5">
                      <img class="icon-barra" *ngFor="let n of [].constructor(52);let i = index" src="assets/Icons/barra.svg" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-7 text-center align-self">
            <div class="sunburst">
              <app-sunburst-graph-conexoes
                [sunburst]="sunburst"
                (sunburstEmitter)="showModal = true"
                [sunburstHeight]="viewHeight"
              ></app-sunburst-graph-conexoes>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-conexoes [openModal]="showModal" (modalEmitter)="showModal = false"></modal-conexoes>
  </div>
</div>
