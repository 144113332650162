import { UniversalAppInterceptor } from './universal.interceptor';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
@NgModule({
 providers: [
  UniversalAppInterceptor,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: UniversalAppInterceptor,
    multi: true,
  },
 ],
})
export class InterceptorModule {}
