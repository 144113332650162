import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css']
})
export class MultiSelectComponent implements OnInit {
  @Input() data: any[];
  @Output() selected = new EventEmitter();

  show: boolean = false;

  constructor(
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {}

  selectAll() {
    this.data.forEach(i => i.selected = true);
    this.sendPillars();
  }

  deselectAll() {
    this.data.forEach(i => i.selected = false);
    this.sendPillars();
  }

  ngOnChanges(): void {
    this.selected.emit(this.data.filter(d => d.selected).map(d => d.id));
  }

  clear(){
    return !this.data?.some(d => d.selected);
  }

  allSelected(){
    return this.data?.filter(d => d.selected)?.length == this.data?.length;
  }

  showSelected(){
    return this.data.filter(d => d.selected)
    .map(d => d.descricao)
    .toString();
  }

  sendPillars() {
    const pillars = this.data.filter(d => d.selected).map(d => d.id);
    this.selected.emit(pillars);
  }

}
