<div class="fixo">
  <div class="sideNavigation">
    <ul>
      <li>
        <a type="button" class="navigation" (click)="scroll('home-component', 1)">
          <div *ngIf="exibir === 1" class="line-navigation"></div>
        </a>
      </li>
      <li>
        <a type="button" (click)="scroll('desafios-component', 2)" class="navigation">
          <div *ngIf="exibir === 2" class="line-navigation"></div>
        </a>
      </li>
      <li>
        <a type="button" class="navigation" (click)="scroll('prova-conceito', 3)">
          <div *ngIf="exibir === 3" class="line-navigation"></div>
        </a>
      </li>
      <li>
        <a type="button" class="navigation" (click)="scroll('programas-ciclo-mstart', 4)">
          <div *ngIf="exibir === 4" class="line-navigation"></div>
        </a>
      </li>
      <li>
        <a type="button" (click)="scroll('esg-component', 5)" class="navigation">
          <div *ngIf="exibir === 5" class="line-navigation"></div>
        </a>
      </li>
      <li>
        <a type="button" (click)="scroll('conexoes-component', 6)" class="navigation">
          <div *ngIf="exibir === 6" class="line-navigation"></div>
        </a>
      </li>
      <!--li>
        <a type="button" (click)="scroll('seguidores-component',4)" class="navigation">
          <div *ngIf="exibir === 5" class="line-navigation"></div>
        </a>
      </li-->
    </ul>
  </div>

  <!--
    'home-component'
    'desafios-component'
    'eventos-component'
    'eventos-por-mineradora-component'
    'seguidores-component'
  -->

  <!-- Componentes de conteúdo -->
  <home-main id="home-component"></home-main>
  <desafios id="desafios-component"></desafios>
  <prova-conceito [desafioParam]="desafioParam" (isShowDesafio)="setIsShowDesafio($event)" id="prova-conceito"></prova-conceito>
  <ciclo [desafioParam]="desafioParam" id="programas-ciclo-mstart"></ciclo>
  <app-esg id="esg-component" (isShowEsg)="setIsShowEsg($event)"></app-esg>
  <conexoes id="conexoes-component"></conexoes>
  <!-- <eventos id="eventos-component"></eventos> -->
  <!--eventos id="eventos-por-mineradora-component"></eventos-->
  <!-- <seguidores id="seguidores-component"></seguidores> -->
  <!-- Componentes de conteúdo -->
  <app-rodape></app-rodape>

  <app-cookies></app-cookies>

</div>


