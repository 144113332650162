import { ChallengerFilterService } from './../../service/challenger-filter/challenger-filter.service';
import { GlobalFilterService } from './../../service/global-filter/global-filter.service';
import { MenuTranslateService } from './../../pages/nav-menu/components/menu-translate/menu-translate.service';
import { DesafiosService } from './desafios.service';
import { Component } from '@angular/core';
import { IChallengeProgram } from './desafios.model';
import { BasePageComponent } from 'src/app/pages/base-page/base-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IGlobalFilter } from 'src/app/service/global-filter/global-filter.model';
import { IDesafioFilter } from '../prova-conceito/prova.model';

@Component({
  selector: 'desafios',
  templateUrl: './desafios.component.html',
  styleUrls: ['./desafios.component.scss']
})
export class DesafiosComponent extends BasePageComponent {
  selectedButton = 'M-START';
  selectedChallengeProgram: IChallengeProgram;
  challengeProgram: IChallengeProgram[];
  viewHeight: number;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public translate: TranslateService,
    public modal: NgbModal,
    private desafiosService: DesafiosService,
    public menuTranslateService: MenuTranslateService,
    private globalFilterService: GlobalFilterService,
    private challengerFilterService: ChallengerFilterService
  ) {
    super(router, route, messageService, translate, modal);
  }

  onInit(): void {
    this.viewHeight = (window.innerHeight - document.getElementById('navHeader')?.clientHeight) || 560;
    this.setSunburstData();
    this.menuTranslateService.getLanguage().subscribe(()=> {
      this.setSunburstData();
    });
    this.globalFilterService.getObserve().subscribe((data) => {
      this.selectedButton = data.program != '-'? data.program: "M-Start";
      this.selectChallengeProgram();
    });
  }

  setSunburstData(){
    this.startLoading();
    this.desafiosService.getChallengeProgram().then(data => {
      this.challengeProgram = data;
      this.selectChallengeProgram();
    }).finally(() => {
      this.stopLoading();
    });
  }

  changeButton(program:string): void {
    if(this.selectedButton.toLowerCase() === program.toLowerCase()){
      return;
    }
    this.selectedButton = program;
    this.selectChallengeProgram();
    this.setGlobalFilter();
  }

  selectChallengeProgram(): void {
    this.selectedChallengeProgram = !this.challengeProgram ? null : this.challengeProgram!
      .find(p => p.program.toUpperCase() === this.selectedButton.toUpperCase());
  }

  getQuant(program: string): number {
    return this.challengeProgram!
      .find(p => p.program.toUpperCase() === program.toUpperCase())
      .quant;
  }

  maisSobreClick(program: string){
    const params: IDesafioFilter = {
      id: 0,
      challenge: "",
      program: program,
      cyclo: 0,
      miner: "", startup: "", tematic:""
    };
    this.challengerFilterService.setChallenger(params);
  }

  setGlobalFilter() {
    const newFilter: IGlobalFilter = {program: this.selectedButton, cycle: 0};
    this.globalFilterService.setFilter(newFilter);
  }

}
