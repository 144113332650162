import { IFiltro, IDesafio } from './prova.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/service/base.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class ServiceProvaConceito {

  data: IFiltro;

  constructor(public http?: HttpClient) {  }

  getFiltro() {
    return this.http.get<IFiltro>(environment.environmentEndpointUrl() + "api/Program/programs-challenges-filters"
    ).toPromise();
  }

  getProgramas(idChallenge:number) {
    return this.http.get<IDesafio>(environment.environmentEndpointUrl() + "api/Program/programs-challenges",
      { params: { idChallenge:idChallenge.toString() } }
    ).toPromise();
  }

}
