import { DatePipe } from '@angular/common';
import { LoginService } from 'src/app/service/login.service';

export { }

declare global {
  interface Date {
    toFormatUtc(format: string): string;
    toFormat(format: string): string;
    toFormatDateTime(): string;
    toFormatDate(): string;
  }
}

Date.prototype.toFormatUtc = function (format: string): string {
  return new DatePipe(LoginService.getSelectedLanguageExtension).transform(this, format, 'UTC');
};

Date.prototype.toFormat = function (format: string): string {
  return new DatePipe(LoginService.getSelectedLanguageExtension).transform(this, format);
};

Date.prototype.toFormatDateTime = function (): string {
  return new DatePipe(LoginService.getSelectedLanguageExtension).transform(this, 'dd/MM/yyyy HH:mm:ss');
};

Date.prototype.toFormatDate = function (): string {
  return new DatePipe(LoginService.getSelectedLanguageExtension).transform(this, 'dd/MM/yyyy');
};
