<div class="eventos">
  <div class="texto">
    <h5>{{"HOME.HOME_PROVIDER.TITLE_EVENTS" | translate}} <mark>{{eventos?.years}}
        {{"HOME.HOME_PROVIDER.TITLE_EVENTS-EM" | translate}}</mark>{{"HOME.HOME_PROVIDER.TITLE_EVENTS-EM-MINING" |
      translate}}</h5>
    <h3>{{eventos?.events}} {{"HOME.HOME_PROVIDER.NUMBER_EVENTS" | translate}}</h3>
    <h5>{{"HOME.HOME_PROVIDER.SUBTITLE-EVENTS" | translate}} <mark> {{"HOME.HOME_PROVIDER.SUBTITLE-EVENTSS" |
        translate}}</mark></h5>
  </div>
</div>
