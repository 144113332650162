<div id="provaDesafiosDetalhes" class="d-flex flex-row justify-content-center w-100 padding-b-10">
  <div class="row main-content" [class.showFilter]="isShow">
    <div *ngIf="!loading" class="container-top elementToFadeIn">
      <div class="line-lateral">
        <div class="section">
          <!-- <div class="back" *ngIf="desafioParam?.challenge" >
         <a routerLink="/home">
           <img class="image-seta" src="assets/images/vector61.png" alt="voltar">
           {{"PROOF_CONCEPT.BACK" | translate}} <b>{{"PROOF_CONCEPT.CHALLENGE_RAISED" | translate}}</b>
         </a>
       </div> -->
          <div class="text-max">
            <h1>{{"PROOF_CONCEPT.CHALLENGES" | translate}}</h1>
          </div>
          <div id="title" (click)="expandir()">
            <h2 id="text-title">{{desafioSelecionado}}</h2>
            <div class="seta">
              <i class="pi pi-angle-down"></i>
            </div>
          </div>
          <div class="line-top"></div>
          <div *ngIf="isShow" class="card card-table-search">
            <div style="padding-left:.5rem;" class="card-header">
              <div style="height: 40px;">
                <button class="button-m form-control export-button" (click)="generateExcel()">
                  <i class="fa fa-file-excel-o export-icon"></i>
                  {{"PROOF_CONCEPT.BUTTON_EXCEL_EXPORT" | translate}}
                </button>
              </div>
              <div class="row align-items-center">
                <div class="col-1 search-table">
                  <label for="search">{{"PROOF_CONCEPT.NAME" | translate}}</label>
                  <input [(ngModel)]="desafioParam.challenge" type="text" class="filtered form-control "
                    [(placeholder)]="placeholder" (blur)="filtrarDesafio()"
                    (keypress)="inputKeypress($event)">
                </div>
                <div class="col-2 pl-0">
                  <label for="" style="margin-left:5px">{{"PROOF_CONCEPT.PROGRAM" | translate}}</label>
                  <div class="buttonMS">
                    <button style="margin:0 .4rem .4rem .4rem;" *ngFor="let program of filtro?.programs"
                      class="button-m form-control" (click)="setProgram(program.program)"
                      [ngClass]="{'buttonSelected': desafioParam.program.toLowerCase() == program.program.toLowerCase()}">
                      {{program.program | uppercase}}
                    </button>
                  </div>
                </div>

                <div class="col-2">
                  <label for="">{{"PROOF_CONCEPT.THEME" | translate}}</label>
                  <select class="select-cycle form-control form-control-sm" [(ngModel)]="desafioParam.tematic"
                    (change)="filtrarDesafio();" [value]="">
                    <option value=""> {{"PROOF_CONCEPT.SELECT_TEMATICS" | translate}}</option>
                    <option *ngFor="let tematic of tematicsFiltro" [value]="tematic"> {{tematic }}</option>
                  </select>
                </div>

                <div class="col-2">
                  <label for="">{{"PROOF_CONCEPT.MINING_COMPANY2" | translate}}</label>
                  <select class="select-cycle form-control form-control-sm" [(ngModel)]="desafioParam.miner"
                    (change)="filtrarDesafio();" [value]="">
                    <option value=""> {{"PROOF_CONCEPT.SELECT_MINERS" | translate}}</option>
                    <option *ngFor="let miner of minerFiltro" [value]="miner">{{miner}}</option>
                  </select>
                </div>

                <div class="col-2">
                  <label for="">{{"PROOF_CONCEPT.STARTUP" | translate}}</label>
                  <select class="select-cycle form-control form-control-sm" [(ngModel)]="desafioParam.startup"
                    (change)="filtrarDesafio();" [value]="">
                    <option value=""> {{"PROOF_CONCEPT.SELECT_STARTUPS" | translate}}</option>
                    <option *ngFor="let startup of startupFiltro" [value]="startup">{{startup}}</option>
                  </select>
                </div>

                <div class="col-1 pl-0">
                  <label for="">{{"PROOF_CONCEPT.CYCLE" | translate}}</label>
                  <select class="select-cycle form-control form-control-sm" [(ngModel)]="desafioParam.cyclo"
                    (change)="filtrarDesafio(); setGlobalFilter();">
                    <option value="0"> {{"PROOF_CONCEPT.SELECT_CYCLES" | translate}}</option>
                    <option *ngFor="let cycle of ciclosFiltro" [value]="cycle">{{"PROOF_CONCEPT.CYCLE" | translate}}
                      {{cycle }}</option>
                  </select>
                </div>

                <div class="col-2">
                  <label for="">{{"PROOF_CONCEPT.PILLAR_ESG" | translate}}</label>
                  <app-multi-select [data]="pillars" (selected)="desafioParam.pillar = $event; filtrarDesafio();">
                  </app-multi-select>
                </div>

              </div>
            </div>
            <div class="table-content">
              <table class="tabela">
                <thead>
                  <tr>
                    <th style="width: 38%;">{{"PROOF_CONCEPT.CHALLENGE" | translate}}</th>
                    <th style="width: 14%;">{{"PROOF_CONCEPT.THEME" | translate}}</th>
                    <th style="width: 13%;">{{"PROOF_CONCEPT.MINING_COMPANY" | translate}}</th>
                    <th style="width: 13%;">{{"PROOF_CONCEPT.STARTUP" | translate}}</th>
                    <th style="width: 10%;">{{"PROOF_CONCEPT.PROGRAM_CYCLO" | translate}}</th>
                    <th style="width: 12%;">{{"PROOF_CONCEPT.PILLAR_ESG" | translate}}</th>
                  </tr>
                </thead>
                <tbody class="border-table">
                  <tr *ngFor="let challenge of listaDesafiosFiltrados"
                    [ngClass]="{'selecionado':challenge.challenge === desafioSelecionado}"
                    (click)="getChallenge(challenge?.id_challenge);scrollChallenge();">
                    <td style="width: 38%; line-height: initial;">{{challenge?.challenge}}</td>
                    <td style="width: 14%;">{{challenge?.tematic}}</td>
                    <td style="width: 13%;">{{challenge?.miners}}</td>
                    <td style="width: 13%;">{{challenge?.startup}}</td>
                    <td style="width: 10%;">{{challenge?.program}} - {{"PROOF_CONCEPT.CYCLE" | translate}}
                      {{challenge?.cycle}}
                    </td>
                    <td style="width: 12%;">{{ challenge?.esg_descriptions }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div id="challengeDetail" [class.content-table]="isShow">
          <div class="actions-top">
            <button class="btn-tab" [class.btn-tab-active]="visaoGeral" (click)="visaoGeral = true">
              {{"PROOF_CONCEPT.OVERVIEW" | translate}}
            </button>
            <button class="btn-tab" [class.btn-tab-active]="!visaoGeral" (click)="visaoGeral = false">
              {{"PROOF_CONCEPT.PROOF_RESULTS" | translate}}
            </button>
          </div>

          <div class="box-top" *ngIf="visaoGeral">
            <div class="box-l" id="programas-prova-conceito">
              <div class="line-sup"></div>
              <div class="d-flex flex-column">
                <div class="row-app" style="margin-top: -10px;">
                  <div class="col-4 infor">
                    <h6>{{"PROOF_CONCEPT.PROGRAM_DESC" | translate}} / {{ "PROOF_CONCEPT.CYCLE" | translate }}</h6>
                  </div>
                  <div class="col-8 infor" style="padding-left: 3rem;">
                    <h6 class="theme">{{"PROOF_CONCEPT.THEME_DESC" | translate}}</h6>
                  </div>
                </div>
                <div class="row-app" style="margin-top: -9px;">
                  <div class="col-4 sub-infor">
                    <h6 class="sub">{{desafios?.challenge?.program}} /{{"PROOF_CONCEPT.CYCLE" |
                      translate}}{{desafios?.challenge?.cycle}}</h6>
                  </div>
                  <div class="col-8 sub-infor" style="padding-left: 3rem;">
                    <h6 class="sub-tematic">{{desafios?.challenge?.tematic}}</h6>
                  </div>
                </div>
                <div class="row-app">
                  <div class="col-4 infor">
                    <h6 class="mine-company">{{"PROOF_CONCEPT.RESPONSABLE_DESC" | translate}}</h6>
                  </div>
                  <div class="col-8 infor" style="padding-left: 3rem;">
                    <h6 class="mine-company">{{"PROOF_CONCEPT.PILLAR_ESG" | translate}}</h6>
                  </div>
                </div>
                <div class="row-app" style="margin-top: -9px;">
                  <div class="col-4 sub-infor">
                    <h6 class="sub-m">{{desafios?.challenge?.miner}}</h6>
                  </div>
                  <div class="col-8 sub-infor relative" style=" padding:0 0 .8rem 3rem;">
                    <div class="d-flex flex-row align-items-end" style="margin-top: -1.4rem;">
                      <h6 class="sub-m float-left" style="min-width:75px;">{{ challengeEsgDescription }}</h6>
                      <ul class="icons-list float-left">
                        <li *ngFor="let imgName of challengeEsgImages">
                          <img class="icone" src="assets/images/esg-icons/{{imgName}}" alt="">
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 infor text-image" style="margin-top: -10px">
                <h3>{{"PROOF_CONCEPT.DESCRIPTION" | translate}}</h3>
                <p class="conteudo">{{desafios?.challenge?.description}}</p>
              </div>
              <div class="line-text-img-b"></div>
            </div>
            <div class="box-image">
              <div class="line-sup2"></div>
              <div class="photoApi">
                <img *ngIf="desafios?.challenge?.img; else notFound" class="photo"
                  [src]="srcImage(desafios?.challenge?.img)" alt="" (click)="modalImg = true">
                <ng-template #notFound>
                  <p class="no-content">
                    {{"CRUD.MSG.NO_CONTENT" | translate}}
                  </p>
                </ng-template>
              </div>
            </div>
          </div>

          <div *ngIf="!loading && !visaoGeral" class="prova-container elementToFadeIn">
            <div *ngIf="desafios?.godmothers?.length > 0; else noPocFound" class="provas-conceito elementToFadeIn">
              <!-- <h2>{{"PROOF_CONCEPT.EVIDENCES" | translate}}</h2> -->
              <div class="mineradora-madrinha">
                <div class="name-miner">
                  <h5>{{"PROOF_CONCEPT.MINING_COMPANY" | translate}} :</h5>
                </div>
                <div class="img-madrinhas">
                  <div class="img1" *ngFor="let godmother of desafios?.godmothers"
                    [ngClass]="godmother?.name === godmotherFilterSelected?.name ? 'imgSelected' : '' ">
                    <img class="miner-image" [src]="srcImage(godmother?.img)" alt="mineradora"
                      (click)="godmotherFilter(godmother)">
                  </div>
                </div>
              </div>
              <div class="especificacoes">
                <div class="borda1 col-3">
                  <div class="img-madrinha-logo">
                    <img class="photo-logo" *ngIf="godmotherFilterSelected?.startup_img"
                      [src]="srcImage(godmotherFilterSelected?.startup_img) " alt="" style="height: 100%;">
                  </div>
                  <h4 class="name-mineradora"> {{godmotherFilterSelected?.startup_name}}</h4>
                  <div class="informacoes-miner">
                    <p class="inf-min"> {{"CONTRACTED_STARTUPS.LOCATION" | translate}}:
                      <span>{{godmotherFilterSelected?.startup_location || "" }}
                        {{godmotherFilterSelected?.startup_location? ", ":""}}
                        {{
                        (godmotherFilterSelected?.startup_country?"countries."+godmotherFilterSelected?.startup_country
                        : "")| translate}}</span>
                    </p>
                    <p class="inf-min"> {{"PROOF_CONCEPT.MINING_COMPANY" | translate}}:
                      <span>{{godmotherFilterSelected?.name || ""}}</span>
                    </p>
                  </div>
                  <p class="p-especificacoes">
                    {{godmotherFilterSelected?.startup_description}}
                  </p>
                </div>
                <div class="solucao col-5">
                  <div class="borda-solucoes">
                    <div class="line-b"></div>
                    <div class="text-box">
                      <h4 class="title">{{"PROOF_CONCEPT.SOLUTION" | translate}}</h4>
                      <p *ngIf="godmotherFilterSelected?.solution; else notFound" class="conteudo2">
                        {{godmotherFilterSelected?.solution}}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="resultado col-4">
                  <div class="borda2">
                    <div class="line-b"></div>
                    <div class="text-box">
                      <h4 class="title">{{"PROOF_CONCEPT.RESULT" | translate}}</h4>
                      <p *ngIf="godmotherFilterSelected?.result; else notFound" class="conteudo3">
                        {{godmotherFilterSelected?.result}}
                      </p>
                    </div>
                  </div>
                </div>
                <ng-template #notFound>
                  <div class="p-container">
                    <p class="no-content">
                      {{"CRUD.MSG.NO_CONTENT" | translate}}
                    </p>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>

        </div>
        <div class="line-b-lateral"></div>
      </div>
    </div>

    <ng-template #noPocFound>
      <div class="no-poc-div">
        <p class="no-poc">
          {{"CRUD.MSG.NOT_POC_FOUNDED" | translate}}
        </p>
      </div>
    </ng-template>
  </div>
</div>

<app-modal-img *ngIf="modalImg" [src]="srcImage(desafios?.challenge?.img)" (closeModal)="close()"></app-modal-img>
