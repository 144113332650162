import { Component, OnDestroy, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { FormControl } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { LoginService } from "src/app/service/login.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from 'primeng/api';
import { StatusEnum } from "src/app/models/enums/status-enum.model";
import { IGlobalFilter } from "src/app/service/global-filter/global-filter.model";
import { GlobalFilterService } from "src/app/service/global-filter/global-filter.service";

@Component({
  selector: "home-main",
  templateUrl: "./home-main.component.html",
  styleUrls: ["./home-main.component.css"],
})
export class HomeMainComponent implements OnInit, OnDestroy {
  filter = new FormControl("");
  loading = false;
  userActive: StatusEnum;
  userSub: any;
  langSub: any;
  rowGroupMetadata: any;
  exibir: number = 1;
  borderOne = false;
  borderTwo = false;
  menuData: any;

  selectedPage = "SUBSCRIBED";
  selectedSession = 'subscribed_startups';

  globalFilter: IGlobalFilter;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public loginService: LoginService,
    public messageService: MessageService,
    public translate: TranslateService,
    public modal: NgbModal,
    private globalFilterService: GlobalFilterService
  ) {
  }

  public onInit() { }

  ngOnInit(): void {
    environment.routeBackTranslate = "";
    environment.previousScreenTitleTranslate = "";

    environment.titleTranslate = 'HOME.TITLE';

    this.globalFilterService.getObserve().subscribe((data) => {
      data.program = data.program != '-'? data.program : 'M-Start';
      this.globalFilter = data;
    });

  }

  ngOnDestroy(): void {
    if (this.userSub != undefined) this.userSub.unsubscribe();
    if (this.langSub != undefined) this.langSub.unsubscribe();
  }

  setSelectedPage(page: string) {
    this.selectedPage = page;
  }

  setSelectedSession(session: string){
    this.selectedSession = session;
  }

  colorButton(value) {
    if (value === 1) {
      this.borderOne = true;
    } else {
      this.borderTwo = true;
    }
  }

  colorLeave(value) {
    if (value === 1) {
      this.borderOne = false;
    } else {
      this.borderTwo = false;
    }
  }

  scroll(line: string, pag: number) {
    this.exibir = pag;
    document.querySelector(line).scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

}
