<div>
  <div class="check" style="z-index: 1;">
    <div  class="visualizar">
      <label for="">{{"CYCLE.VIEW_BY" | translate}}</label>
      <div class="mineradora" (click)="changeVisualizarPor('miners')" [ngClass]="visualizarPor === 'miners'? 'selected-button':''">
        <span>{{"CYCLE.MINER" | translate}}</span>
      </div>
      <div class="tematica" (click)="changeVisualizarPor('tematics')" [ngClass]="visualizarPor === 'tematics'? 'selected-button':''">
        <span>{{"CYCLE.CHALLENG" | translate}}</span>
      </div>
    </div>
  </div>
  <div id="container-barras"></div>
</div>
