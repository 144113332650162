import { Component, OnInit } from '@angular/core';
import { IFollow, ISeguidores } from './seguidores.model';
import { ServiceSeguidores } from './seguidores.service';

@Component({
  selector: 'seguidores',
  templateUrl: './seguidores.component.html',
  styleUrls: ['./seguidores.component.css']
})
export class TelaSeguidoresComponent implements OnInit {

 constructor( private serviceSeguidores: ServiceSeguidores,) { }

  dados:ISeguidores;

  seguidores:IFollow[] = [];

  ngOnInit(): void {


    this.serviceSeguidores.getSegue().then(dados => {
      this.dados = dados;

      this.seguidores = dados.followers.sort((n1,n2) => {
        return (Number(n1.quant) < Number(n2.quant))? 1: -1;
      });
    });
  }

}
