import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MenuTranslateService {
    lang$ = new Subject();

    constructor() { }

    selectedLang(language){
        return this.lang$.next(language);
    }

    getLanguage(){
        return this.lang$.asObservable();
    }

}
