import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ICicloData, ICicloFiltros } from './ciclo.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CicleService  {

  constructor(public http?: HttpClient) { }

  public getCiclo(program?: string, cycle?: string) {
    return this.http.get<ICicloData>(environment.environmentEndpointUrl() + "api/Program/programs-cicle",
      { params: { program: program, cycle: cycle } }
    ).toPromise();
  }

  public getCicloFiltro() {
    return this.http.get<ICicloFiltros[]>(environment.environmentEndpointUrl() + "api/Program/programs-cicle-filters"
    ).toPromise();
  }

}
