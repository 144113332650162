import { HomeMinerService } from './home-miner.service';
import { IHomeMiner } from './home-miner.model';
import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { environment } from "src/environments/environment";
import { FormControl } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from 'primeng/api';
import { StatusEnum } from "src/app/models/enums/status-enum.model";
import { BasePageComponent } from 'src/app/pages/base-page/base-page.component';
import { IHome } from '../home-subscribed/home-subscribed.model';

@Component({
  selector: "home-miner",
  templateUrl: "./home-miner.component.html",
  styleUrls: ["./home-miner.component.scss"],
})
export class HomeMinerComponent extends BasePageComponent implements OnDestroy {
  @Input() menuData;
  @Input() botaoSelecionado = 'miner';
  @Output() selectedPage = new EventEmitter<string>();
  @Output() selectedSession = new EventEmitter<string>();

  filter = new FormControl("");
  loading = false;
  userActive: StatusEnum;
  userSub: any;
  langSub: any;
  rowGroupMetadata: any;
  exibir: number = 1;
  borderOne = false;
  borderTwo = false;

  data: IHome;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public translate: TranslateService,
    public modal: NgbModal,
    private homeMinerService: HomeMinerService,

  ) {
    super(router, route, messageService, translate, modal);
  }

  onInit(): void {
    environment.routeBackTranslate = "";
    environment.previousScreenTitleTranslate = "";

    environment.titleTranslate = 'HOME.TITLE';

    this.startLoading();
    this.homeMinerService.getMiners().then(data => {
      this.data = data;
    }).finally(()=>{
      this.stopLoading();
    });

  }

  ngOnDestroy(): void {
    if (this.userSub != undefined) this.userSub.unsubscribe();
    if (this.langSub != undefined) this.langSub.unsubscribe();
  }

  selectComponent(component: string, session: string) {
    this.selectedPage.emit(component);
    this.selectedSession.emit(session);
  }

  selectSession(session: string){
    this.selectedSession.emit(session);
  }

  getButtonClass(button: string): string {
    return this.botaoSelecionado === button? "numberTela1": "numberTela";
  }

  clickButton(button: string) {
    this.botaoSelecionado = button;
  }

  colorButton(value) {
    if (value === 1) {
      this.borderOne = true;
    } else {
      this.borderTwo = true;
    }
  }

  colorLeave(value) {
    if (value === 1) {
      this.borderOne = false;
    } else {
      this.borderTwo = false;
    }
  }

  scroll(line: string, pag: number) {
    this.exibir = pag;
    document.querySelector(line).scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
