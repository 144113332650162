import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/service/base.service';
import { IContractedFilter, IContractedFilterParam, IContractedFiltred } from './home-contratadas.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ProgramsContractedsService extends BaseService {

  constructor(public http: HttpClient) { super(http); }

  public getContractedsFilters() {
    return this.http.get<IContractedFilter>(environment.environmentEndpointUrl()+"api/Program/programs-contracteds-filters"
    ).toPromise();
  }

  public getContracteds(filtro: IContractedFilterParam, top: boolean) {
    return this.http.get<IContractedFiltred[]>(environment.environmentEndpointUrl()+"api/Program/programs-contracteds",
      {
        params: {
          program: filtro.program,
          contractor: filtro.contractor.name,
          cycle: filtro.cycle,
          top: top.toString()
        }
      }).toPromise();
  }
}
