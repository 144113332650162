<div class="content-background elementToFadeInModal" *ngIf="openModal">
  <div [class.show]="openModal" class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content pb-3">
        <div class="modal-body pl-0 pt-5">
          <img (click)="close()" src="assets/Icons/close.svg" alt="" class="close">
          <div class="title-block">
            <div class="title p-2">
              {{ data.companyName }}
            </div>
            <div class="line-bottom-blue mt-1"></div>
          </div>

        <div class="row pl-5">
          <div class="col-4 mt-3">
            <div class="bordaButton1"></div>
            <div class="company p-3">
              <img class="company-logo" src="data:image/png;base64,{{ data.companyImg }}" alt="">
              <ul class="location pt-2">
                <li class="country">
                  {{"CONTRACTED_STARTUPS.LOCATION" | translate}}: <b>{{ data.location }}</b>
                </li>
                <li>
                  {{"HOME.HOME_SUBSCRIBED.CONNECTIONS_MADE" | translate}}: <b>{{ data.realizedConnections }}</b>
                </li>
              </ul>
            </div>
            <div class="bordaButton2"></div>
          </div>
          <div class="col-8 mt-3">
              <div class="title-m">
                <h1 class="mark">{{ data?.connectionTitle }}</h1>
              </div>
            <div class="bordaButton1"></div>
            <div class="company-description f-company p-3" *ngIf="data?.connectionDescription; else notFound">
              {{ data?.connectionDescription }}
            </div>
            <div class="bordaButton2"></div>
          </div>
        </div>
        <div class="row pl-5 mt-3">
          <div class="section-title">
            {{"HOME.HOME_SUBSCRIBED.CONECTION" | translate}}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-4 pl-5 pr-0">
            <div class="connections-content">
              <div *ngFor="let connection of data.connections" class="col-4 mt-2 pr-0">
                <div class="connections" [class.selected]="connection == selectedConnection" [title]="connection.companyName" (click)="selectConnection(connection)">
                  <img *ngIf="connection.img" src="data:image/png;base64,{{ connection.img }}" class="img-connections">
                </div>
              </div>
            </div>
          </div>
          <div class="col-8 pr-0 pl-5 mt-2">
            <div class="borda-solucoes">
              <div class="line-b"></div>
              <div class="container-solucoes">
                <h1 class="pl-3">{{"PROOF_CONCEPT.DESCRIPTION" | translate}}</h1>
                <div class="company-description pl-3" *ngIf="selectedConnection.description; else notFound">
                  {{ selectedConnection.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template  #notFound>
    <div class="not-found">
    <div class="p-container" class="box-not">
      <p class="no-content">
        {{"CRUD.MSG.NO_CONTENT" | translate}}
      </p>
    </div>
  </div>
  </ng-template>
</div>
