<div class="secao-ciclo">
  <div class="linha elementToFadeIn">
    <div *ngIf="!this.loading">
      <div class="container-principal">
        <div class="filtro">
          <div class="dropdown d-flex flex-column align-items-start justify-content-start">
            <div class="d-flex flex-row">
              <ul style="width: 300px; " class="customSelect"
                (click)="showPrograms = !showPrograms; showCycles = false">
                <li *ngFor="let program of programs"
                  [ngClass]="{'selected': program.program.toLowerCase() === programaSelecionado.toLowerCase() }"
                  class="main">
                  {{program.program }}
                </li>
              </ul>
              <div class="custom-options d-flex flex-column align-items-start justify-content-center">
                <div class="seta" (click)="showPrograms = !showPrograms; showCycles = false">
                  <i class="pi pi-angle-down"></i>
                </div>
                <div class="showPrograms" *ngIf="showPrograms">
                  <ul class="p-0">
                    <li *ngFor="let program of programs" class="options"
                      (click)="selectProgram(program.program)">{{
                      program.program }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row mt-5">
              <ul id="cicloSelectCycles" style="cursor: pointer;" class="customSelect-c m-0"
                (click)="showCycles = !showCycles; showPrograms = false">
                <li *ngFor="let cycle of cycles" [ngClass]="{'selected': cycle === cicloSelecionado}">{{"CYCLE.CYCLE_M"
                  | translate}} {{cycle}}</li>
              </ul>
              <div class="custom-options d-flex flex-column align-items-start justify-content-center">
                <div class="seta s2" (click)="showCycles = !showCycles; showPrograms = false">
                  <i class="pi pi-angle-down"></i>
                </div>
                <div>
                  <div class="showCycles" *ngIf="showCycles">
                    <ul class="m-0 p-0">
                      <li *ngFor="let cycle of cycles" class="options"
                        (click)="selectCycle(cycle); getData(programaSelecionado, cicloSelecionado); showCycles = false">
                        {{"CYCLE.CYCLE_M" | translate}} {{cycle}}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row mt-5 status-do-ciclo-fin">
              {{ "CYCLE.CYCLE_STATUS" | translate }} {{ data?.status.etapa }} -
              {{ "CYCLE.START" | translate }} {{ data?.status.dtStart | date: getDateFormat()}} /
              {{ "CYCLE.END" | translate }} {{ data?.status.dtEnd | date: getDateFormat()}}
            </div>
          </div>
        </div>
        <div class="numbers-top">
          <div class="numbers-desafios">
            <div class="borda-left" style="margin:0 -2rem -2rem 0;"></div>
            <div class="box-button">
              <button class="BigNumber">{{data?.info.raised_challenges}}</button>
              <p class="paragraphBG">{{"CYCLE.CHALLENGES_COMPANY" | translate}}</p>
            </div>
            <div class="box-button">
              <button class="BigNumber">{{data?.info.priorized_challenges}}</button>
              <p class="paragraphBG">{{"CYCLE.PRIORIZED" | translate}}</p>
            </div>
            <div class="box-button">
              <div class="bordaButton1-1" style="margin-bottom: -2.3rem;"></div>
              <button class="BigNumber">
                {{data?.info.realized_pocs}}
              </button>
              <p style="width: 100px; margin-left: .8rem" class="paragraphBG">{{"CYCLE.POCS_PERFORMED" | translate}}</p>
            </div>
            <div class="box-button">
              <button class="BigNumber">
                {{data?.info.subscribed_startups}}
              </button>
              <p style="margin-left: .5rem;" class="paragraphBG">{{"CYCLE.REGISTERED_S" | translate}}</p>
            </div>
            <div class="box-button">
              <button class="BigNumber">
                {{data?.info.selecteds_startups}}
              </button>
              <p class="paragraphBG">{{"CYCLE.SELECTED_S" | translate}}</p>
            </div>
            <div class="box-button">
              <div class="bordaButton1-1" style="margin-bottom: -2.3rem;"></div>
              <button class="BigNumber">
                {{data?.info.contracteds_startups}}
              </button>
              <p class="paragraphBG">{{"CYCLE.CONTRACTED" | translate}}</p>
            </div>
          </div>
          <div class="borda-rigth"></div>
        </div>
      </div>
      <div class="graphic" id="ciclo-mstart">
        <app-bar-graph [data]="data?.graphic"></app-bar-graph>
      </div>
    </div>
  </div>
</div>
