<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{titulo | translate}}</h4>
  <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss('cross')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p class="m-0"><strong>{{mensagem | translate}}</strong></p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="modal.close('nao')">{{"MSGBOX.BOTAO_NAO" | translate}}</button>
  <button type="button" ngbAutofocus class="btn btn-primary" (click)="modal.close('sim')">{{"MSGBOX.BOTAO_SIM" | translate}}</button>
</div>
