import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IGlobalFilter } from './global-filter.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalFilterService {
    public filter$ = new Subject<IGlobalFilter>();

    constructor() {
      const newFilter: IGlobalFilter = {program: 'M-Start', cycle: 0};
      this.filter$.next(newFilter);
    }

    setFilter(filter:IGlobalFilter){
        return this.filter$.next(filter);
    }

    getObserve(){
        return this.filter$.asObservable();
    }

    async getFilter(){
      return await this.filter$.pipe().subscribe((data) => data);
    }

}
