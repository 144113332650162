<div class="container-fluid box-footer">
  <div class="row">
    <div class="col-2"></div>
    <div class="col-8">
      <div class="d-flex flex-row justify-content-center links-item">
        <ul>
          <li>
            <a href="https://www.mininghub.com.br/" aria-current="page">{{"HOME.HOME_SUBSCRIBED.HOME" | translate}}</a>
          </li>
          <li>
            <a href="https://www.mininghub.com.br/quem-somos/">{{"HOME.HOME_SUBSCRIBED.WHO_WE_ARE" | translate}}</a>
          </li>
          <li>
            <a href="https://www.mininghub.com.br/programas/">{{"HOME.HOME_SUBSCRIBED.PROGRAMS" | translate}}</a>
          </li>
          <li>
            <a href="https://www.mhnumbers.com.br/mininghub/#/">{{"HOME.HOME_SUBSCRIBED.MH_IN_NUMBERS" | translate}}</a>
          </li>
          <li>.

            <a href="https://www.mininghub.com.br/blog/">{{"HOME.HOME_SUBSCRIBED.BLOG" | translate}}</a>
          </li>
          <li>
            <a href="https://www.mininghub.com.br/agenda/">{{"HOME.HOME_SUBSCRIBED.SCHEDULE" | translate}}</a>
          </li>
          <!-- <li>
            <a routerLink="/home">Portal</a>
          </li> -->
        </ul>
      </div>
      <div class="row employers">
        <div class="col-4 d-flex flex-column justify-content-end align-items-center">
          <div class="footer-title">
            <h3 class="left-title"> {{"HOME.HOME_SUBSCRIBED.SPONSIRSHIP" | translate}} </h3>
          </div>
          <img src="assets/images/vale_white.png" alt="" class="logo-vale">
        </div>
        <div class="col-4 d-flex flex-column justify-content-center align-items-center">
          <div class="footer-title">
            <h3 class="center-title">{{"HOME.HOME_SUBSCRIBED.FOLLOW_OUR_SOCIAL" | translate}}<br> {{"HOME.HOME_SUBSCRIBED.FOLLOW_OUR_SOCIAL2" | translate}}</h3>
          </div>
          <div class="d-flex flex-row justify-content-center align-items-center w-100">
            <a href="https://www.facebook.com/hubdamineracao/" target="_blank">
              <img src="assets/images/facebook.png" alt="" class="redes-sociais">
            </a>
            <a href="https://www.linkedin.com/company/mininghub/" target="_blank">
              <img src="assets/images/linkedIn.png" alt="" class="redes-sociais">
            </a>
            <a href="https://www.instagram.com/hubdamineracao/" target="_blank">
              <img src="assets/images/instragam.png" alt="" class="redes-sociais">
            </a>
            <a href="https://www.youtube.com/channel/UCnTaII9iPtfzUPkmmUhXYgw?sub_confirmation=1" target="_blank">
              <img src="assets/images/youtube.png" alt="" class="redes-sociais">
            </a>
            <a href="https://tiktok.com/@mininghub" target="_blank">
              <img src="assets/images/tiktok.png" alt="" class="redes-sociais">
            </a>
            <a href="https://twitter.com/hubdamineracao" target="_blank">
              <img src="assets/images/twitter.png" alt="" class="redes-sociais">
            </a>
          </div>
        </div>
        <div class="col-4 d-flex flex-column justify-content-center align-items-center">
          <div class="footer-title">
            <h3 class="right-title">{{"HOME.HOME_SUBSCRIBED.TALK_TO" | translate}}<br>{{"HOME.HOME_SUBSCRIBED.TALK_TO2" | translate}}</h3>
          </div>
          <p class="email">contato@mininghub.com.br</p>
        </div>
      </div>
    </div>
    <div class="col-2"></div>
  </div>
</div>
