import { PbiPageComponent } from './pages/pbi-page/pbi-page.component';
import { WrongRouteComponent } from './pages/wrong-route/wrong-route.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { LoginComponent } from "./pages/login/login.component";
import { NavMenuComponent } from "./pages/nav-menu/nav-menu.component";
import { AccessErrorComponent } from "./pages/access-error/access-error.component";
import { LogoutResolver } from "./service/resolver/logout.resolve";
import { PermissionGuardService } from './service/permission-guard.service';

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    component: NavMenuComponent,
    children: [{ path: "", component: HomeComponent }],
  },
  {
    path: "login",
    component: NavMenuComponent,
    children: [{ path: "", component: LoginComponent }],
  },
  {
    path: "home",
    component: NavMenuComponent,
    children: [{ path: "", component: HomeComponent }],
  },
  {
    path: "pbi",
    component: NavMenuComponent,
    children: [
      { path: "", component: PbiPageComponent },
    ],
    canActivate: [PermissionGuardService]
  },
  {
    path: "logout",
    component: NavMenuComponent,
    children: [{ path: "", component: HomeComponent }],
    resolve: { link: LogoutResolver },
  },
  {
    path: "access-denied",
    component: NavMenuComponent,
    data: { errorType: "ACCESS-DENIED" },
    children: [{ path: "", component: AccessErrorComponent }],
  },
  {
    path: "access-no-permission",
    component: NavMenuComponent,
    data: { errorType: "ACCESS-NO-PERMISSION" },
    children: [{ path: "", component: AccessErrorComponent }],
  },
  {
    path: '404',
    component: WrongRouteComponent
  },
  {
    path: '**',
    component: WrongRouteComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
