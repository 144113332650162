<div class="card">
  <div class="card-effect" (click)="cardClick()">
    <span></span><span></span>
      <div class="associated-inside-container">
        <div class="associated-image-container">
          <div style="margin: auto;">
            <img *ngIf="contracted?.img" class="associated-image" [src]="srcImage(contracted?.img)"/>
          </div>
        </div>
        <div class="associated-text-container">
          <div class="associated-detail-name">
            <b class="associated-label-name">{{contracted?.name}}</b>
          </div>
          <div class="associated-detail-text">
            {{'CONTRACTED_STARTUPS.LOCATION' | translate}}
            <b class="associated-detail-text-bold">{{contracted?.location}}, {{contracted?.country | translate}} &nbsp;</b>
          </div>
          <div class="associated-detail-text">
            {{'CONTRACTED_STARTUPS.CONTRACTOR' | translate}}
            <b class="associated-detail-text-bold">{{contracted?.contractor}} &nbsp;</b>
          </div>
          <!-- <div class="associated-detail-text">
            {{'CONTRACTED_STARTUPS.CHALLENGE' | translate}}
            <b class="associated-detail-text-bold">{{contracted?.challenge}} &nbsp;</b>
          </div> -->
          <div class="associated-detail-text">
            {{"PROOF_CONCEPT.PROGRAM" | translate}}
            <b class="associated-detail-text-bold">{{contracted?.program}},{{"PROOF_CONCEPT.CYCLE" | translate}} {{contracted?.cycle | number: '2.0-0'}} &nbsp;</b>
          </div>
         <div class="associated-detail-description btxt">
            <p>{{contracted?.description}}..</p>
          </div>
        </div>
        <a [href]="contracted?.website" target="_blank">
          <div>
          <b class="associated-site-label">{{'CONTRACTED_STARTUPS.WEBSITE' | translate}}</b>
          </div>
        </a>
      </div>

  </div>
</div>
