import { IGraphicBar, IGraphicBarItemValue } from './../ciclo/ciclo.model';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';

declare var require: any;
let sunburst = require('highcharts/modules/sunburst')

sunburst(Highcharts);

@Component({
  selector: 'app-bar-graph',
  templateUrl: './bar-graph.component.html',
  styleUrls: ['./bar-graph.component.css']
})
export class BarGraphComponent implements OnInit, OnChanges {
  @Input() data: IGraphicBar;

  selectedValue: IGraphicBarItemValue[];
  selectedAxis: string[];
  visualizarPor: string = "miners";

  public options: any;

  highcharts = Highcharts;

  ngOnChanges(changes: SimpleChanges) {
    if (this.data?.miners.values.length > 0) {
      this.createChart();
    }
  }

  constructor(public translate: TranslateService) { }

  ngOnInit() { }

  createChart(): void {
    this.selectedValue = this.data[this.visualizarPor].values;
    this.selectedAxis = this.data[this.visualizarPor].xaxis;

    this.selectedValue.forEach(async (element, i) => {
      await this.translate.get(element.name).subscribe(res => {
        this.selectedValue[i].name = res;
      })
    });

    this.options = {
      chart: {
        type: 'column',
        backgroundColor: 'rgb(0,0,0,0)',
        marginTop: 80
      },
      title: {
        text: ' '
      },
      subtitle: {
        text: ' '
      },
      legend: {
        verticalAlign: 'top',
        align: 'left',
        x: 0,
        y: 0,
        itemStyle: {
          color: '#F2F2F2',
          fontSize: '14px'
        },
        itemHoverStyle: {
          color: '#F2F2F2'
        },
        symbolRadius: '2px'
      },
      colors: ['#00FFFF', '#FFFF00', '#d8d8d8'],
      xAxis: {
        categories: this.selectedAxis,
        crosshair: true,
        labels: {
          rotation: -45,
          style: {
            color: '#F2F2F2',
          }
        }
      },
      yAxis: {
        gridLineColor: '#414141',
        min: 0,
        title: {
          text: ' '
        },
        labels: {
          style: {
            color: '#F2F2F2'
          }
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:16px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: &nbsp &nbsp </td>' +
          '<td style="padding:0;font-size: 12px;"><b>{point.y}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
        backgroundColor: '#303030',
        style: {
          color: '#F2F2F2',
          fontSize: '12px',
          whiteSpace: 'nowrap'
        }
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          borderWidth: 0,
          width: 12,
        }
      },
      series: this.selectedValue
    };

    Highcharts.chart('container-barras', this.options, this.removeCredit);
  }

  changeVisualizarPor(selecionado: string):void {
    this.visualizarPor = selecionado;
    this.selectedValue = this.data[this.visualizarPor].values;
    this.selectedAxis = this.data[this.visualizarPor].xaxis;
    this.createChart();

}

  removeCredit(){
    (<HTMLElement>document.getElementsByClassName('highcharts-credits')[0]).style.setProperty('display', "none");
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

}
