import { Injectable, APP_INITIALIZER } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ConfigService {
  static InitProvider = {
    provide: APP_INITIALIZER,
    deps: [ConfigService],
    multi: true,
    useFactory: (service: ConfigService) => {
      return async () => {
        await service.getConfig();
      };
    }
  };

  config;

  constructor(private http: HttpClient) { }

  getConfig() {
    return this.http.get('assets/config/config.json').toPromise().then(
      data => {
        this.config = data;
      });
  }
}
