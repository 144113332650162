import { Component, ChangeDetectorRef, AfterViewChecked } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { LoginService } from "./service/login.service";

@Component({
  selector: "app-root",
  template: `
    <app-loading *ngIf="this.loading"></app-loading>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements AfterViewChecked {
  public static loading = false;
  private static activeElement;

  public static startLoading() {
    AppComponent.activeElement = document.activeElement;
    AppComponent.loading = true;
  }

  public static stopLoading() {
    AppComponent.loading = false;

    let cont = 0;
    const interval = setInterval(() => {
      try {
        AppComponent.activeElement.focus();
        if (
          cont >= 5 ||
          AppComponent.activeElement === document.activeElement
        ) {
          clearInterval(interval);
        }
      } catch (error) {
        clearInterval(interval);
      }
      cont++;
    }, 10);
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private titleService: Title,
    private loginService: LoginService
  ) {
    this.titleService.setTitle(
      `${environment.appName}`
    );
    }

  ngOnInit() {
    let language = LoginService.getSelectedLanguageDefault;
    if (language && language != "") {
      this.loginService.setSelectedLanguage(language);
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  public get loading() {
    return AppComponent.loading;
  }
}
