import { ExcelService, IExcelModel } from './../../service/excel.service';
import { ChallengerFilterService } from './../../service/challenger-filter/challenger-filter.service';
import { GlobalFilterService } from './../../service/global-filter/global-filter.service';
import { IFiltro, IDesafio, IChallengeFilter, IGodmother, IDesafioFilter, IDescription } from './prova.model';
import { ServiceProvaConceito } from './prova.service';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MenuTranslateService } from 'src/app/pages/nav-menu/components/menu-translate/menu-translate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePageComponent } from 'src/app/pages/base-page/base-page.component';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IGlobalFilter } from 'src/app/service/global-filter/global-filter.model';



@Component({
  selector: 'prova-conceito',
  templateUrl: './prova.component.html',
  styleUrls: ['./prova.component.css']
})

export class ProvaConceitoComponent extends BasePageComponent {
  @Input() desafioParam : IDesafioFilter = {id: 0, tematic: "", cyclo: 0, challenge: "", miner: "", program: "M-Start", startup: ""};
  @Output() isShowDesafio = new EventEmitter<boolean>();

  loading = true;
  isShow = false;
  filtro: IFiltro;
  ciclosFiltro: number[];
  tematicsFiltro: string[];
  startupFiltro: string[];
  minerFiltro: string[];
  listaDesafiosFiltrados: IChallengeFilter[];
  desafioSelecionado: string;
  modalImg: boolean = false;
  changeLanguage: boolean = false;

  desafios: IDesafio;
  lastChallengeId: number;
  godmotherFilterSelected: IGodmother;
  pillars: any[];
  pillarsDescription: string;
  challengeEsgDescription: string;
  challengeEsgImages: any[];
  placeholder: string;

  visaoGeral: boolean = true;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public translate: TranslateService,
    public modal: NgbModal,
    private service: ServiceProvaConceito,
    public menuTranslateService: MenuTranslateService,
    private globalFilterService: GlobalFilterService,
    private challengerFilterService: ChallengerFilterService,
    private excelService: ExcelService
  ) {
    super(router, route, messageService, translate, modal);
  }

  onInit(): void {
    this.desafioParam.tematic = "";
    this.desafioParam.miner = "";
    this.desafioParam.startup = "";
    this.setDesafiosData();
    this.translate_placeholder("PROOF_CONCEPT.SEARCH");
    this.getDesafiosByLanguage();
    this.globalFilterService.getObserve().subscribe((data) => {
      this.visaoGeral = true;
      const programTemp = this.desafioParam.program;
      const cycleTemp = this.desafioParam.cyclo;
      this.desafioParam.program = data.program != '-' ? data.program : 'M-Start';
      //this.desafioParam.cyclo = data.cycle;
      if(cycleTemp !== data.cycle) {
        this.desafioParam.challenge = "";
        this.desafioParam.tematic = "";
        this.desafioParam.miner = "";
        this.desafioParam.startup = "";
        this.desafioParam.cyclo = 0;
        this.desafioParam.pillar = [];
        this.pillars?.forEach(i => i.selected = false);
      }
      if((data.cycle === 0 || programTemp !== this.desafioParam.program) && this.filtro) {
        this.desafioParam.challenge = "";
        this.desafioParam.tematic = "";
        this.desafioParam.miner = "";
        this.desafioParam.startup = "";
        this.desafioParam.cyclo = 0;
        this.desafioParam.pillar = [];
        this.pillars?.forEach(i => i.selected = false);

        this.setCycleFilter();
        this.setMinerFilter();
        this.setStartupFilter();
        this.setTematicFilter();
      }
      this.filtrarDesafio();
    });
    this.challengerFilterService.getObserve().subscribe(async (data) => {
      this.visaoGeral = data.startup == '';
      if(data.miner != '' || data.startup != ''){
        this.desafioParam.tematic = "";
        this.desafioParam.challenge = "";
        this.desafioParam.pillar = [];
        this.pillars.forEach(i => i.selected = false);

        this.desafioParam.miner = data.miner;
        this.desafioParam.startup = data.startup;
        this.setTematicFilter();
        this.setMinerFilter();
        this.setStartupFilter();
        this.scrollProva(true);
        this.filtrarDesafio();
      } else {
        this.desafioParam = data;
        this.getChallenge(data.id , true);
        this.scrollProva(false);
        this.setGlobalFilter()
      }
    });
    this.menuTranslateService.getLanguage().subscribe(()=>{
      this.translate_placeholder("PROOF_CONCEPT.SEARCH");
    });
  }

  generateExcel() {
    this.translate.get("PROOF_CONCEPT.CHALLENGE_LIST").subscribe((title) => {
      this.translate.get("PROOF_CONCEPT.WORKSHEETNAME_CHALLENGES").subscribe((worksheetName) => {
        this.translate.get("PROOF_CONCEPT.FILENAME_CHALLENGE").subscribe((fileName) => {
          this.translate.get("PROOF_CONCEPT.EXPORT_FILTERS").subscribe((exportFilters) => {
            let params = "";
            params = this.replaceAll(JSON.stringify(this.desafioParam), '""', 'N/A');
            params = this.replaceAll(params, '{','');
            params = this.replaceAll(params, '}','');
            params = this.replaceAll(params, '"','');
            params = this.replaceAll(params, ':',': ');
            params = this.replaceAll(params, ',',', ');
            const content: IExcelModel = {
              title: title,
              worksheetname: worksheetName,
              filename: fileName+".xlsx",
              footer: exportFilters+params,
              columnWidth: [15, 20, 10, 10, 30, 30, 20, 25, 10],
              header: Object.keys(this.listaDesafiosFiltrados[0]),
              data: this.listaDesafiosFiltrados.map(i => Object.values(i))
            };
            this.excelService.generateExcel(content);
          });
        });
      });
    });
  }

  public replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  delay(ms: number) {
    return new Promise( resolve => {
      setTimeout(resolve, ms)
    });
  }

  getDesafiosByLanguage(){
    this.menuTranslateService.getLanguage().subscribe((data: string) => {
      this.changeLanguage = true;
      this.setDesafiosData();
    });
  }

  setDesafiosData(){
    this.startLoading();
    this.service.getFiltro().then(data => {
      this.listaDesafiosFiltrados = [...data.challenges];
      this.filtro = data;
      this.pillars = data.programs[0].esg;
      this.aplicarFiltros();
      this.setCycleFilter();
      this.setTematicFilter();
      this.setMinerFilter();
      this.setStartupFilter();
    });
  }

  setProgram(program){
    this.desafioParam.program = program;
    this.desafioParam.miner = "";
    this.desafioParam.tematic = "";
    this.desafioParam.startup = "";
    this.setCycleFilter();
    this.setTematicFilter();
    this.setMinerFilter();
    this.setStartupFilter();
    this.setGlobalFilter();
  }

  aplicarFiltros() {
    if(!this.desafioParam.challenge) {
      this.getChallenge(this.filtro.challenges![0].id_challenge , this.desafioParam.pillar?.length > 0);
      this.desafioSelecionado = this.filtro.challenges![0].challenge;
      this.desafioParam.program = this.filtro.challenges[0].program;
      //this.desafioParam.cyclo = this.filtro.challenges[0].cycle;
    } else {
      const challenge = this.filtro.challenges.find(p => p.id_challenge === this.desafioParam.id);
      this.getChallenge(challenge.id_challenge, this.desafioParam.pillar?.length > 0);
      this.desafioSelecionado = challenge.challenge;
      this.desafioParam.challenge = challenge.challenge;
      this.listaDesafiosFiltrados = this.filtro.challenges.filter(p => {
        return //p.cycle == challenge.cycle &&
                p.program == challenge.program &&
                p.challenge == challenge.challenge;
        });
    }
  }

  inputKeypress(event) {
    if(event.key === 'Enter') {
      this.filtrarDesafio();
      this.desafioParam.startup = '';
    }
  }

  filtrarDesafio() {
    const filtro = this.desafioParam;

    if(!this.filtro) {
      return;
    }

    this.listaDesafiosFiltrados = this.filtro.challenges;

    if(filtro.challenge){
      this.listaDesafiosFiltrados = this.listaDesafiosFiltrados.filter(f => f.challenge.toLowerCase().includes(filtro.challenge.toLowerCase()))
    }

    if(filtro.program){
      this.listaDesafiosFiltrados = this.listaDesafiosFiltrados.filter(f => f.program.toUpperCase() == filtro.program.toUpperCase());
    }

    if(filtro.cyclo != 0){
      this.listaDesafiosFiltrados = this.listaDesafiosFiltrados.filter(f => f.cycle == filtro.cyclo);
    }

    if(filtro.tematic != ''){
      this.listaDesafiosFiltrados = this.listaDesafiosFiltrados.filter(f => f.tematic.toLowerCase() == filtro.tematic.toLowerCase());
    }

    if(filtro.miner != ''){
      let filter : IChallengeFilter[] = [];
      this.listaDesafiosFiltrados.forEach(item => {
        if(item.miners.includes(filtro.miner)) {
          filter.push(item);
        }
      });
      this.listaDesafiosFiltrados = filter;
    }

    if(filtro.startup != ''){
      let filter : IChallengeFilter[] = [];
      this.listaDesafiosFiltrados.forEach(item => {
        if(item.startup.includes(filtro.startup)) {
          filter.push(item);
        }
      });
      this.listaDesafiosFiltrados = filter;
    }

    if(filtro.pillar?.length){
      this.listaDesafiosFiltrados = this.listaDesafiosFiltrados.filter(f => {
        if(f.esg_ids && f.esg_ids.some(f => filtro.pillar.includes(f))){
          return f;
        }
      });
    }
    this.getChallenge(this.listaDesafiosFiltrados![0]?.id_challenge , false);
  }

  getChallenge(idChallenge: number, editaFiltro: boolean = false) {
    if(!idChallenge || idChallenge === 0 || (this.lastChallengeId === idChallenge && !this.changeLanguage )) {
      return;
    }
    this.lastChallengeId = idChallenge;
    this.startLoading();
    this.service.getProgramas(idChallenge).then(dados => {
      this.desafios = dados;
      this.godmotherFilterSelected = dados.godmothers![0];
      this.desafioSelecionado = this.filtro.challenges.find(p => p.id_challenge === idChallenge).challenge;
      this.getPillarsDescriptions(idChallenge);
      this.challengeEsgImages = dados.ESG_Type.map(img => img.descricao.toLowerCase()+'.svg');
      if(editaFiltro){
        this.selectedPillars(dados.ESG_Type);
      }
    }).finally(()=>{
      this.changeLanguage = false;
      this.loading = false;
      this.stopLoading();
    });
  }

  selectedPillars(pillars){
    pillars = pillars.map(p => p.id);
    this.pillars.map(p => (pillars.includes(p.id)) ? p.selected = true : p.selected = false);
  }

  getPillarsDescriptions(idChallenge){
    this.challengeEsgDescription = this.filtro.challenges.filter(desafio => desafio.id_challenge === idChallenge)[0].esg_descriptions;
  }

  srcImage(base64: string): string {
    return `data:image/png;base64,${base64}`;
  }

  godmotherFilter(godmother: IGodmother) {
    this.godmotherFilterSelected = godmother;
  }

  protected setCycleFilterFullList(){
    let setCycles = new Set<number>();
    this.filtro.programs.forEach( item => {
      item.cycles.forEach(element => {
        setCycles.add(element);
      });
    });
    this.ciclosFiltro = [...setCycles].sort((a, b) => a - b).reverse();
  }

  public setCycleFilter(){
    let setCycles = new Set<number>();
    const program = this.filtro.programs.find( p => p.program.toUpperCase() === this.desafioParam.program.toUpperCase());
      program.cycles.forEach(element => {
        setCycles.add(element);
      });
    this.ciclosFiltro = [...setCycles].sort((a, b) => a - b).reverse();
    //this.desafioParam.cyclo = this.ciclosFiltro[0];
  }

  protected setMinerFilterFullList(){
    let setMiners = new Set<string>();
    this.filtro.programs.forEach( item => {
      item.miners.forEach(element => {
        setMiners.add(element.descricao);
      });
    });
    this.minerFiltro = [...setMiners].sort((a, b) => a > b && 1 || -1);
  }

  public setMinerFilter(){
    let setMiners = new Set<string>();
    const program = this.filtro.programs.find( p => p.program.toUpperCase() === this.desafioParam.program.toUpperCase());
      program.miners.forEach(element => {
        setMiners.add(element.descricao);
      });
    this.minerFiltro = [...setMiners].sort((a, b) => a > b && 1 || -1);
  }

  protected setStartupFilterFullList(){
    let setStartups = new Set<string>();
    this.filtro.programs.forEach( item => {
      item.startup.forEach(element => {
        setStartups.add(element.descricao);
      });
    });
    this.startupFiltro = [...setStartups].sort((a, b) => a > b && 1 || -1);
  }

  public setStartupFilter(){
    let setStartups = new Set<string>();
    const program = this.filtro.programs.find( p => p.program.toUpperCase() === this.desafioParam.program.toUpperCase());
      program.startup.forEach(element => {
        setStartups.add(element.descricao);
      });
    this.startupFiltro = [...setStartups].sort((a, b) => a > b && 1 || -1);
  }

  protected setTematicFilterFullList(){
    let setTematic = new Set<string>();
    this.filtro.programs.forEach( item => {
      item.tematics.forEach(element => {
        setTematic.add(element.descricao);
      });
    });
    this.tematicsFiltro = [...setTematic].sort((a, b) => a > b && 1 || -1);
  }

  public setTematicFilter(){
    let setTematic = new Set<string>();
    const program = this.filtro.programs.find( p => p.program.toUpperCase() === this.desafioParam.program.toUpperCase());
      program.tematics.forEach(element => {
        setTematic.add(element.descricao);
      });
    this.tematicsFiltro = [...setTematic].sort((a, b) => a > b && 1 || -1);
  }


  close(){
    this.modalImg = false;
  }

  setGlobalFilter(){
    const newFilter: IGlobalFilter = {program: this.desafioParam.program, cycle: this.desafioParam.cyclo};
    this.globalFilterService.setFilter(newFilter);
  }

  expandir(){
    this.isShow = !this.isShow;
    this.isShowDesafio.emit(this.isShow);
  }

  translate_placeholder(key){
    this.translate.get(key).subscribe((text)=>{
      this.placeholder = text;
    });
  }

  async scrollChallenge() {
    await this.delay(500);
    const componentRect = document.getElementById('challengeDetail').getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();
    window.scroll(0,componentRect.top - bodyRect.top - 60);
  }

  private async scrollProva(show) {
    this.isShow = show;
    await this.delay(500);
    const componentRect = document.getElementById('provaDesafiosDetalhes').getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();
    window.scroll(0,componentRect.top - bodyRect.top - 60);
  }

}

