import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-pbi-page",
  templateUrl: "./pbi-page.component.html",
  styleUrls: ["./pbi-page.component.css"],
})
export class PbiPageComponent implements OnInit {

  loading = false;

  constructor() {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
