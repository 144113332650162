<div ngbDropdown [container]="isBodyContainer ? 'body' : ''" style="padding-top: 1rem;">
  <button class="btn cor-font font-caecilia-bold translate" style="box-shadow: none;" ngbDropdownToggle>
    <img alt="" style="margin-right: 5px; border-radius: 50%; width:1.43rem; height:1.18rem"
      [src]="'assets/images/bandeiras_navbar/' + this.selectedLanguage + '.png'" />{{this.selectedLanguage.toUpperCase()}}
  </button>
  <div ngbDropdownMenu style="min-width: auto; right: 0;">
    <button *ngFor="let item of this.languages" class="cor-font font-caecilia-bold" ngbDropdownItem
      (click)="this.loginService.setSelectedLanguage(item); selectTranslate(item)">
      <img alt="" style="margin-right: 5px; border-radius: 50%; width:1.43rem; height:1.18rem"
        [src]="'assets/images/bandeiras_navbar/' + item + '.png'" />{{item.toUpperCase()}}
    </button>
  </div>
</div>
