
  <div class="card">
    <div class="card-effect">
      <div class="click" (click)="onClick()">
       <span></span><span></span>
        <div class="associated-inside-container">
          <div class="box-card">
            <div class="card-icons">
              <img class="section-icons" src="assets/images/esg-icons/environment.svg" *ngIf="verificaTipo('environment')" alt="enviroment">
              <img class="section-icons" src="assets/images/esg-icons/social.svg" *ngIf="verificaTipo('social')" alt="social">
              <img class="section-icons" src="assets/images/esg-icons/governance.svg" *ngIf="verificaTipo('governance')" alt="governance">
              <div class="card-program">
                {{ item?.program }} / {{"CYCLE.CYCLE_M" | translate}} {{item?.cycle }}
              </div>
            </div>
              <div class="card-text">
                {{ item?.description }}
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
