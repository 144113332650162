import { ServiceEventos } from './eventos.service';
import { IEventos } from './eventos.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.css']
})
export class EventosComponent implements OnInit {

  constructor(private serviceEventos:ServiceEventos) { }

  eventos:IEventos;

  ngOnInit(): void {
    
    this.serviceEventos.getEvent().then(eventos =>{
      this.eventos = eventos;
    })


  }

}