import { ChallengerFilterService } from './../../../service/challenger-filter/challenger-filter.service';
import { IItem } from './../../esg/esg.model';
import { Component, Input, OnInit } from '@angular/core';
import { IDesafioFilter } from '../../prova-conceito/prova.model';

@Component({
  selector: 'app-card-esg',
  templateUrl: './card-esg.component.html',
  styleUrls: ['./card-esg.component.scss']
})
export class CardEsgComponent implements OnInit {

  @Input() item: IItem;

  constructor(
    private challengerFilterService: ChallengerFilterService
    ) {}

  ngOnInit(): void {}

  verificaTipo(tipo:string){
    return this.item?.esg_type.find(p => p.toLowerCase() === tipo.toLowerCase());
  }

  onClick(){
    const params: IDesafioFilter = {
      id: this.item.challenge_id,
      challenge: this.item.description,
      program: this.item.program,
      cyclo: this.item.cycle,
      miner: "", startup: "", tematic:""
    };
    this.challengerFilterService.setChallenger(params);
  }

}
