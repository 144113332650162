import { ServiceHomeData } from './home-subscribed.service';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { environment } from "src/environments/environment";
import { FormControl } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SortEvent, MessageService } from 'primeng/api';
import { StatusEnum } from "src/app/models/enums/status-enum.model";
import { IHome, ICountry, ISelectContratante } from './home-subscribed.model';
import { BasePageComponent } from 'src/app/pages/base-page/base-page.component';

@Component({
  selector: "home-subscribed",
  templateUrl: "./home-subscribed.component.html",
  styleUrls: ["./home-subscribed.component.css"],
})
export class HomeSubscribedComponent extends BasePageComponent implements OnInit, OnDestroy {
  @Input() menuData;
  @Input() botaoSelecionado = 'subscribed_startups';
  @Output() sendMenuData = new EventEmitter();
  @Output() selectedPage = new EventEmitter<string>();
  @Output() selectedSession = new EventEmitter<string>();
  @ViewChild('tolltipMap') tolltipMap : ElementRef;

  @HostListener('mousemove', ['$event']) onMouseMove(event) {
    const height =  this.tolltipMap.nativeElement!.children[0].children[0].children[0].clientHeight;
    const width =  this.tolltipMap.nativeElement!.children[0].children[0].children[0].clientWidth;
    const scrollTop = document.body.getBoundingClientRect().top;
    this.tolltipMap.nativeElement!.style.setProperty('left',`${event.clientX - (width/2)}px`);
    this.tolltipMap.nativeElement!.style.setProperty('top',`${event.clientY - height - 20 - scrollTop}px`);
  }

  filter = new FormControl("");
  loading = false;
  userActive: StatusEnum;
  userSub: any;
  langSub: any;
  rowGroupMetadata: any;
  exibir: number = 1;
  borderOne = false;
  borderTwo = false;
  data:IHome = null;
  paisSelecionado: ICountry;
  showContracts:boolean = false;

  selectContratante:ISelectContratante[] = [];
  selectedContratante:string = null;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public translate: TranslateService,
    public modal: NgbModal,
    private serviceHomeData: ServiceHomeData,
  ) {
    super(router, route, messageService, translate, modal);
  }

  public onInit() { }

  ngOnInit(): void {
    environment.routeBackTranslate = "";
    environment.previousScreenTitleTranslate = "";
    environment.titleTranslate = 'HOME.TITLE';

    this.startLoading();
    this.serviceHomeData.getData().then((data: IHome) => {
      this.data = data;
      this.sendMenuData.emit(data);
    }).finally(async ()=>{
      this.stopLoading();
    });
  }

  ngOnDestroy(): void {
    if (this.userSub != undefined) this.userSub.unsubscribe();
    if (this.langSub != undefined) this.langSub.unsubscribe();
  }

  selectComponent(component: string, session: string) {
    this.selectedPage.emit(component);
    this.selectedSession.emit(session);
  }

  sortHome(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) result = -1;
      else if (value1 != null && value2 == null) result = 1;
      else if (value1 == null && value2 == null) result = 0;
      else if (typeof value1 === "string" && typeof value2 === "string")
        result = value1.localeCompare(value2);
      else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order * result;
    });
  }

  submit() {
    this.loading = true;
  }

  ShowToast() {
    this.messageService.add({ severity: 'success', summary: "Hello world!", detail: "Toastr fun!" });
  }

  colorButton(value) {
    if (value === 1) {
      this.borderOne = true;
    } else {
      this.borderTwo = true;
    }
  }

  colorLeave(value) {
    if (value === 1) {
      this.borderOne = false;
    } else {
      this.borderTwo = false;
    }
  }

  scroll(line: string, pag: number) {
    this.exibir = pag;
    document.querySelector(line).scrollIntoView({ behavior: 'smooth', block: 'center' });

  }

  mouseoverPais(pais: string) {
    const countries = this.data[this.botaoSelecionado].countries.filter(p => p.country?.toLowerCase() === pais?.toLowerCase());
    if(countries.length === 0) {
      return;
    }
    this.paisSelecionado = countries[0];
    this.tolltipMap.nativeElement!.style.setProperty('display', 'initial');
  }

  mouseoutPais() {
    this.tolltipMap.nativeElement!.style.setProperty('display', 'none');
    this.paisSelecionado = undefined;
  }

  getButtonClass(button: string): string {
    return this.botaoSelecionado === button? "numberTela1": "numberTela";
  }

  getPaisClass(pais: string): string {
    try{
      const countries = this.data[this.botaoSelecionado].countries.filter(p => p.country?.toLowerCase() === pais?.toLowerCase());
      if(pais ==='Canada') {
        pais;
      }
      if(countries.length === 0) {
        return "pais-nao-disponivel";
      }
      if(countries.length > 0 && !this.paisSelecionado) {
        return "pais-disponivel";
      }
      if(this.paisSelecionado && countries.length > 0 && this.paisSelecionado!.country?.toLowerCase() === pais?.toLowerCase()) {
        return "pais-disponivel";
      }
      if(this.paisSelecionado && countries.length > 0 && this.paisSelecionado!.country?.toLowerCase() !== pais?.toLowerCase()) {
        return "pais-nao-selecionado";
      }
    }catch(e){}
  }

  clickButton(button: string) {
    this.botaoSelecionado = button;
  }

  srcImage(base64:string):string{
    return `data:image/png;base64,${base64}`;
  }

}
