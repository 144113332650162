<div *ngIf="this.challengeProgram !== null" class="background elementToFadeIn">
<div class="main" [style]="'height:'+viewHeight+'px;'">
  <div class="main-sunburst">
    <div class="col-12">
      <div class="row">
        <div class="col-5 d-flex flex-row">
          <div class="menu-lateral col">
            <div class="row">
              <div class="mstart-mspot-main col">
                <div class="row">
                  <button class="vertical1 col-6" [ngClass]="selectedButton.toUpperCase() ==='M-START'? 'button-selected':'' "
                  (click)="changeButton('M-START')">M-START</button>
                  <div class="challenges-one col-6">
                      <h5 class="bg-yellow">{{challengeProgram ? getQuant("M-START") : ''}}+</h5>
                    <h4 class="descricao-titulo">{{"HOME.HOME_PROVIDER.MAPPED_CHALLENGES" | translate}}</h4>
                    <p>
                      {{"HOME.HOME_PROVIDER.PARAGRAPH_MSTART" | translate}}
                    </p>
                    <a (click)="maisSobreClick('M-START')" class="button bold"
                      title="Meet the Mining Hub">{{"HOME.HOME_PROVIDER.BUTTON_MSTART" | translate}}<span></span><span></span>
                    </a>
                  </div>
                </div>
                <div class="spacer"></div>
                <div class="row">
                  <button class="vertical2 col-6" [ngClass]="selectedButton.toUpperCase() ==='M-SPOT'? 'button-selected':'' "
                  (click)="changeButton('M-SPOT')">M-SPOT</button>
                  <div class="challenges-two col-6">
                      <h5 class="bg-yellow">{{challengeProgram ? getQuant("M-SPOT") : ''}}+</h5>
                    <h4 class="descricao-titulo"> {{"HOME.HOME_PROVIDER.MAPPED_CHALLENGES" | translate}}</h4>
                    <p>
                      {{"HOME.HOME_PROVIDER.PARAGRAPH_MSPOT" | translate}}
                    </p>
                    <a (click)="maisSobreClick('M-SPOT')" class="button bold"
                      title="Meet the Mining Hub">{{"HOME.HOME_PROVIDER.BUTTON_MSPOT" | translate}}<span></span><span></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-7 text-center">
          <div class="sunburst">
            <app-sunburst-graph
              [sunburst]="selectedChallengeProgram?.sunburst"
              [programa]="selectedButton"
              [sunburstHeight]="viewHeight"
              >
            </app-sunburst-graph>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
