import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AppComponent } from 'src/app/app.component';
import { MsgBoxComponent } from 'src/app/components/msg-box/msg-box.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Component({
  template: ''
})
export abstract class BasePageComponent implements OnInit {

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public messageService: MessageService,
    public translate: TranslateService,
    public modal: NgbModal) { }

  ngOnInit(): void {
    this.onInit();
  }

  public abstract onInit();

  startLoading() { AppComponent.startLoading(); }

  stopLoading() { AppComponent.stopLoading(); }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url]);
  }

  protected redirecionarRota(rota: string) {
    this.router.navigate([rota]);
  }

  public onError(erro: HttpErrorResponse) {
    this.stopLoading();

    if (erro.status === 403) {
      this.exibirToastErroTranslated('ERRO.PERMISSAO');
      return;
    }

    if (erro.status === 401) {
      this.exibirToastErroTranslated('ERRO.CONEXAO_EXPIROU');
      this.redirecionarRota('/');
      return;
    }

    if ((erro.status === 500) && (erro?.error?.ExceptionMessage)) {
      this.exibirToastErro(erro?.error?.ExceptionMessage);
      return;
    }

    if ((erro.status === 417) && (typeof erro?.error === 'string')) {
      this.exibirToastErro(erro?.error);
      return;
    }

    this.exibirToastErroTranslated('ERRO.COMUNICACAO_API');
    console.log(erro);
  }

  protected exibirToastSucesso(mensagem: string, titulo?: string) {
    this.messageService.add({ severity: 'success', summary: titulo, detail: mensagem });
  }

  protected exibirToastSucessoTranslated(mensagem: string, titulo?: string, params?) {
    this.translate.get([mensagem, titulo], params).subscribe(res => {
      this.messageService.add({ severity: 'success', summary: res[titulo], detail: res[mensagem] });
    });
  }

  protected exibirToastErro(mensagem: string, titulo?: string) {
    this.messageService.add({ severity: 'error', summary: titulo, detail: mensagem });
  }

  protected exibirToastErroTranslated(mensagem: string, titulo?: string, params?) {
    this.translate.get([mensagem, titulo], params).subscribe(res => {
      this.messageService.add({ severity: 'error', summary: res[titulo], detail: res[mensagem] });
    });
  }

  protected exibirToastAtencao(mensagem: string, titulo?: string) {
    this.messageService.add({ severity: 'warn', summary: titulo, detail: mensagem });
  }

  protected exibirToastAtencaoTranslated(mensagem: string, titulo?: string, params?) {
    this.translate.get([mensagem, titulo], params).subscribe(res => {
      this.messageService.add({ severity: 'warn', summary: res[titulo], detail: res[mensagem] });
    });
  }

  protected exibirToastAtencaoTranslatedAndParam(mensagem: string, titulo?: string, param?: string) {
    this.translate.get(param).subscribe(res => {
      this.exibirToastAtencaoTranslated(mensagem, titulo, { valor: res })
    });
  }

  protected exibirToastApi(apiReturn) {
    if (apiReturn.sucess) {
      this.exibirToastSucessoTranslated(apiReturn.message, 'CRUD.TITULO.SUCESSO');
    } else {
      if ((apiReturn.message as string)?.includes('ATENCAO')) {
        this.exibirToastAtencaoTranslated(apiReturn.message, 'CRUD.TITULO.ATENCAO');
      } else {
        this.exibirToastErroTranslated(apiReturn.message, 'CRUD.TITULO.ERRO');
        if ((apiReturn.message as string)?.includes('ERRO')) {
          console.log('ERRO:', apiReturn.data);
        }
      }
    }
  }

  protected exibirMsgBoxSimNao(mensagem: string, titulo: string, execSim?: () => void, execNao?: () => void, centered = true) {
    const modalRef = this.modal.open(MsgBoxComponent, { centered });
    modalRef.componentInstance.mensagem = mensagem;
    modalRef.componentInstance.titulo = titulo;
    modalRef.result.then((result) => {
      if ((result === 'sim') && (execSim)) {
        execSim();
      } else if ((result === 'nao') && (execNao)) {
        execNao();
      }
    }, (dismiss) => { });
  }
}
