import { ISunburstData } from './conexoes.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/service/base.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ConexoesService extends BaseService {

  constructor(public http: HttpClient) { super(http); }

  public getChallengeProgram() {
    return this.http.get<ISunburstData>(environment.environmentEndpointUrl()+"api/Home/sunburst-conexoes").toPromise();
  }

}
