<div class="main p-5">
<div class=" background">

  <div class="redes-top">

    <div class="linkedin">
      <h3>{{ seguidores![0]?.quant_formatted }}</h3>
     <p>{{ seguidores![0]?.name }}</p>

    </div>
  <div class="youtube">

    <h3>{{ seguidores![3]?.quant_formatted }}</h3>
   <p>{{ seguidores![3]?.name }}</p>

  </div>
    <div class="twitter">

      <h3>{{ seguidores![4]?.quant_formatted }}</h3>
     <p>{{ seguidores![4]?.name }}</p>

    </div>
  </div>
    <div class="text">
        <h1>{{dados?.total_followers}}</h1>
        <h2>{{"HOME.HOME_SUBSCRIBED.TITLE_FOLLOWERS" | translate}}</h2>
       </div>

        <div class="redes-bottom">
          <div class="facebook">
              <h3>{{ seguidores![2]?.quant_formatted }}</h3>
         <p>{{ seguidores![2]?.name }}</p>

          </div>
          <div class="tiktok">
            <h3>{{ seguidores![5]?.quant_formatted }}</h3>
           <p>{{ seguidores![5]?.name }}</p>

          </div>
          <div class="instagram">
            <h3>{{ seguidores![1]?.quant_formatted }}</h3>
           <p>{{ seguidores![1]?.name }}</p>

          </div>
        </div>
      </div>
</div>
