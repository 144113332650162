import { Component, Input, OnInit } from '@angular/core';
import { ChallengerFilterService } from 'src/app/service/challenger-filter/challenger-filter.service';
import { IContractedFiltred } from '../../home/contratadas/home-contratadas.model';
import { IDesafioFilter } from '../../prova-conceito/prova.model';

@Component({
  selector: 'app-card-contracted',
  templateUrl: './card-contracted.component.html',
  styleUrls: ['./card-contracted.component.scss']
})
export class CardContractedComponent implements OnInit {
  @Input() contracted: IContractedFiltred;

  constructor(
    private challengerFilterService: ChallengerFilterService
  ) { }

  ngOnInit(): void {
  }

  srcImage(base64:string):string{
    return `data:image/png;base64,${base64}`;
  }

  cardClick(){
    const params: IDesafioFilter = {
      id: 0,
      challenge: '',
      program: this.contracted.program,
      cyclo: this.contracted.cycle,
      miner: '',
      startup: this.contracted.name,
      tematic: ""
    };
    this.challengerFilterService.setChallenger(params);
  }

}
