import { LocalStorageService } from './../localStorage.service';
import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class UniversalAppInterceptor implements HttpInterceptor {

  constructor(
    private localStorageService: LocalStorageService
    ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.localStorageService.get("token");
    req = req.clone({
      url:  req.url,
      setHeaders: {
        Authorization: `Bearer ${token || ""}`,
        Lang: this.localStorageService.get("languageSelected") || "pt"
      }
    });
    return next.handle(req);
  }
}
