import { ISunburstData } from './conexoes.model';
import { ConexoesService } from './conexoes.service';
import { Component } from '@angular/core';
import { BasePageComponent } from 'src/app/pages/base-page/base-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MenuTranslateService } from 'src/app/pages/nav-menu/components/menu-translate/menu-translate.service';

@Component({
  selector: 'conexoes',
  templateUrl: './conexoes.component.html',
  styleUrls: ['./conexoes.component.css']
})
export class ConexoesComponent extends BasePageComponent {
  showModal: boolean;
  sunburst: ISunburstData;
  viewHeight: number;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public translate: TranslateService,
    public ngModal: NgbModal,
    private conexoesService: ConexoesService,
    public menuTranslateService: MenuTranslateService
  ) {
    super(router, route, messageService, translate, ngModal);
   }

  onInit(): void {
    this.viewHeight = (window.innerHeight - document.getElementById('navHeader')?.clientHeight) || 560;
    this.startLoading();
    this.setSunburstData();
    this.menuTranslateService.getLanguage().subscribe(()=> {
      this.setSunburstData();
   })

  }

  setSunburstData(){
    this.startLoading();
    this.conexoesService.getChallengeProgram().then((data: ISunburstData) => {
      this.sunburst = data;
    }).finally(()=>{
      this.stopLoading();
    });
  }

}
