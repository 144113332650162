export enum Ambiente {
  Desenvolvimento = 0,
  Homologacao = 1,
  Producao = 2,
  DevLocal = 3
};

export const environment = {
  appName: 'MHNumbers',
  version: '1.0.11',
  languages: ["pt", "en"],
  languagesExt: { pt: 'pt-BR', en: 'en-US', es: 'es-ES' },
  onlyIconsNavMenu: false,
  previousScreenTitleTranslate: '',
  previousButtonTitleTranslate: '',
  titleTranslate: '',
  routeBackTranslate: null,
  production: true,
  ambiente: Ambiente.Producao,
  environmentEndpointUrl: () => { return 'https://cockpitdigital-apis-prd.azurewebsites.net/'; }
};
