import { Pipe, PipeTransform } from '@angular/core';
import { LoginService } from 'src/app/service/login.service';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dateutc', pure: false })
export class DateUtcPipe implements PipeTransform {
  transform(value: Date, format: string): string {
    return new DatePipe(LoginService.getSelectedLanguageExtension).transform(value, format, 'UTC');
  }
}
