<div class="main-sunburst">
  <div id="centro-sunburst" class="centro-sunburst">
    <p class="centro-sunburst-numero">
      {{sunburst?.raised_challenges}}
    </p>
    <p class="centro-sunburst-texto">
      {{"CHALLENGES.RAISED_CHALLENGERS" | translate}}
    </p>
    <p class="centro-sunburst-texto-amarelo">
      {{programa | uppercase}}
    </p>
  </div>
  <div id="container"></div>
</div>
