import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IDesafioFilter } from 'src/app/components/prova-conceito/prova.model';

@Injectable({
  providedIn: 'root'
})
export class ChallengerFilterService {
    public filter$ = new Subject<IDesafioFilter>();

    constructor() {
      const newFilter: IDesafioFilter = {id: 0, challenge: "", cyclo: 0, program: "", miner: "", startup: "", tematic:""};
      this.filter$.next(newFilter);
    }

    setChallenger(filter:IDesafioFilter){
        return this.filter$.next(filter);
    }

    getObserve(){
        return this.filter$.asObservable();
    }

    async getChallenger(){
      return await this.filter$.pipe().subscribe((data) => data);
    }

}
