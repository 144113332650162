import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NavMenuService } from "src/app/service/nav-menu.service";
import { environment } from "src/environments/environment";
import { BasePageComponent } from "../base-page/base-page.component";
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MessageService } from 'primeng/api';

@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.scss"],
})
export class NavMenuComponent extends BasePageComponent implements OnInit {
  public collapseNavMenu = true;
  public userName = "";
  public activeUser = false;
  public version = environment.version;



  mainClass: string;



  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public translate: TranslateService,
    public modal: NgbModal,
    private navMenuService: NavMenuService,
  ) {
    super(router, route, messageService, translate, modal);
  }
  public onInit() {

  }

  ngOnInit(): void {
    const noPaddingScreens = [];
    this.route.url.subscribe((url) => {
      this.mainClass = noPaddingScreens.includes(url[0]?.path)
        ? "no-padding-top"
        : "top-2rem";
    });
  }

  someMatchParent(enumObj: object, permissions: string[]) {
    // return true;
      for (const key in enumObj) {
        if (Array.isArray(enumObj[key])) {
          const enumList: string[] = enumObj[key];
          const enumResul = enumList.some((enumValue) => {
            if (permissions.includes(enumValue))
            return true;
          });

          if(enumResul){
            return true;
          }
        }
        else if (permissions.includes(enumObj[key]))
        return true;
      }

  }

  someMatchSub(enumList: readonly any[], permissions: string[]) {
    // return true;
    const enumResul = enumList.some((enumValue) => {
      if (permissions.includes(enumValue))
      return true;
    });
    if(enumResul){
      return true;
    }
  }

  someMatchScreen(enumItem: string, permissions: []) {
    // return true;
    return permissions.some((permission) => permission === enumItem);
  }

  getMenuCss(index) {
    let cssMenu = "nav-item nav-item-group";
    return cssMenu;
  }

  getSubMenuCss(index) {
    let cssMenu = "dropdown-content";
    return cssMenu+" opened";
  }

  getSecondSubMenuCss(subMenu) {
    let cssMenu = "dropdown-content";
    if (subMenu.opened) {
      cssMenu += " open";
    }
    return cssMenu;
  }

  subMenuSecondOpen(subItem, secondSubItem, collapse: boolean) {
    if (subItem.children) {
      if (secondSubItem) {
        subItem.children.forEach((element) => {
          if (secondSubItem.name == element.name)
            if (!subItem.opened) {
              subItem.opened = true;
            } else {
              subItem.opened = false;
            }
        });
      } else {
        if (!subItem.opened) {
          subItem.opened = true;
        } else {
          subItem.opened = false;
        }
      }
    }

    this.setOnlyIconsNavMenu(collapse);
  }

  openParent(colapsed: boolean, index: number) {
    this.openSubMenu(colapsed, index);
  }

  openSubMenu(colapsed: boolean, index: number) {
    this.setOnlyIconsNavMenu(colapsed);
  }

  menuItemClick(menuItem, colapsed: boolean, index: number) {
    if (menuItem.type == "link" || menuItem.type == "child") {
      this.toggleNavMenu();
    } else if (menuItem.type == "drop") this.openSubMenu(colapsed, index);
  }

  toggleNavMenu() {
    this.collapseNavMenu = !this.collapseNavMenu;
  }

  toggleOnlyIconsNavMenu() {
    environment.onlyIconsNavMenu = !environment.onlyIconsNavMenu;
  }

  getOnlyIconsNavMenu = () => environment.onlyIconsNavMenu;

  setOnlyIconsNavMenu = (valor: boolean) =>
    (environment.onlyIconsNavMenu = valor);

  getTitle = () => "";

  getPreviousTitle = () => environment.previousScreenTitleTranslate;

  getPreviousButtonTitle = () =>
    environment.previousButtonTitleTranslate || "CRUD.MSG.VOLTAR_PARA_CONSULTA";

  getRouteBack = () => environment.routeBackTranslate;

  getRouteBackIsString = () =>
    typeof environment.routeBackTranslate === "string";

  execRouteBack() {
    environment.routeBackTranslate();
  }

  mhInNumbers(){
    this.router.navigate(
      ['home']
    );
  }

}
