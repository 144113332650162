// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export enum Ambiente {
  Desenvolvimento = 0,
  Homologacao = 1,
  Producao = 2,
  DevLocal = 3,
};

const baseSiteEndpoint = {

  UrlServiceProdApi: "",
  UrlServiceHomApi: "",
  UrlServiceDevApi: "http://localhost/",
  //UrlServiceDevLocalApi: "https://localhost:44300/",
  UrlServiceDevLocalApi: "https://cockpitdigital-apis-dev.azurewebsites.net/"
};

export const environment = {
  appName: "MHNumbers",
  version: "1.0.11",
  languages: ["pt", "en"],
  languagesExt: { pt: "pt-BR", en: "en-US", es: "es-ES" },
  onlyIconsNavMenu: false,
  previousScreenTitleTranslate: "",
  previousButtonTitleTranslate: "",
  titleTranslate: "",
  routeBackTranslate: null,
  production: false,
  ambiente: Ambiente.DevLocal,
  environmentEndpointUrl: () => {
    if (environment.ambiente === Ambiente.Producao) {
      return baseSiteEndpoint.UrlServiceProdApi;
    } else if (environment.ambiente === Ambiente.Homologacao) {
      return baseSiteEndpoint.UrlServiceHomApi;
    } else if (environment.ambiente === Ambiente.Desenvolvimento) {
      return baseSiteEndpoint.UrlServiceDevApi;
    } else if (environment.ambiente === Ambiente.DevLocal) {
      return baseSiteEndpoint.UrlServiceDevLocalApi;
    }
  },
};
