<div class="main-home">
 <div class="row">
  <div class="flex-fill" ng-style="background-image: url(/src/assets/images/bg-grainy-black.png);">
    <home-subscribed *ngIf="selectedPage === 'SUBSCRIBED'" [botaoSelecionado]="selectedSession" (sendMenuData)="menuData = $event" (selectedPage)="setSelectedPage($event)" (selectedSession)="setSelectedSession($event)" class="flex-fill"></home-subscribed>
    <home-miner [menuData]="menuData" *ngIf="selectedPage === 'MINER'" (selectedPage)="setSelectedPage($event)" (selectedSession)="setSelectedSession($event)" class="flex-fill"></home-miner>
    <home-supplier [menuData]="menuData" *ngIf="selectedPage === 'SUPPLIERS'"(selectedPage)="setSelectedPage($event)" (selectedSession)="setSelectedSession($event)" class="flex-fill"></home-supplier>
    <home-contratadas [menuData]="menuData" [globalFilter]="globalFilter" *ngIf="selectedPage === 'STARTUPSCONTRACTED'"(selectedPage)="setSelectedPage($event)" (selectedSession)="setSelectedSession($event)" class="flex-fill"></home-contratadas>
  </div>
 </div>
</div>
