import { Component, Input, OnInit } from '@angular/core';
import { ChallengerFilterService } from 'src/app/service/challenger-filter/challenger-filter.service';
import { IGlobalFilter } from 'src/app/service/global-filter/global-filter.model';
import { GlobalFilterService } from 'src/app/service/global-filter/global-filter.service';
import { IMiner } from '../../home/home-miner/home-miner.model';
import { IDesafioFilter } from '../../prova-conceito/prova.model';

@Component({
  selector: 'app-card-miners',
  templateUrl: './card-miners.component.html',
  styleUrls: ['./card-miners.component.scss']
})
export class CardMinersComponent implements OnInit {
  @Input() associated: IMiner;

  constructor(
    private challengerFilterService: ChallengerFilterService,
    private globalFilterService: GlobalFilterService
  ) {}

  ngOnInit(): void {
  }

  srcImage(base64:string):string{
    return `data:image/png;base64,${base64}`;
  }

  cardClick(){
    const paramGlobal: IGlobalFilter = {program: this.associated.lastProgram, cycle: this.associated.lastCycles};
    this.globalFilterService.setFilter(paramGlobal);

    const paramDesafio: IDesafioFilter = {
      id: 0,
      challenge: '',
      program: '',
      cyclo: 0,
      miner: this.associated?.name,
      startup: "",
      tematic: ""
    };
    this.challengerFilterService.setChallenger(paramDesafio);
  }

}
