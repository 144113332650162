import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IEventos } from './eventos.model';


@Injectable({
    providedIn: 'root'
  })

export class ServiceEventos{
  constructor (private https: HttpClient){}

  getEvent() {
    return this.https.get<IEventos>(environment.environmentEndpointUrl()+"api/Home/events"
    ).toPromise();
  }

}
