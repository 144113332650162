import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BasePageComponent } from './base-page.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';

export abstract class BasePageFormComponent extends BasePageComponent {

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public messageService: MessageService,
    public translate: TranslateService,
    public modal: NgbModal,
    public fb: FormBuilder) {
    super(router, activatedRoute, messageService, translate, modal);
  }

  form: FormGroup;

  setFormBuild(value) { this.form = this.fb.group(value); }

  setFormPatch(value) { this.form.patchValue(value); }

  getControl(key) { return this.form.controls[key]; }

  getControlValue(key) { return this.form.controls[key].value; }

  setControlValue(key, value, options?) { this.form.controls[key].setValue(value, options); }

  isControlInvalid(key): boolean {
    return this.form.controls[key].invalid && (this.form.controls[key].dirty || this.form.controls[key].touched);
  }

  getControlError(key, error) { return this.form.controls[key].errors[error]; }

  isControlError(key, error, dirty): boolean {
    const control = this.form.controls[key];
    return control.invalid && (!control.errors.required) && control.errors[error] && (control.touched || (dirty && control.dirty));
  }

  setControlDisable(key, value: boolean) {
    if (value) {
      this.form.controls[key].disable({ onlySelf: true });
    } else {
      this.form.controls[key].enable({ onlySelf: true });
    }
  }

  setControlDisableDependency(key, keyDependency) { this.setControlDisable(key, !this.getControlValue(keyDependency)); }

  controlReset(key) { this.form.controls[key].reset(); }

  markAsUntouched(key) { this.form.controls[key].markAsUntouched(); }

  updateValueAndValidity(key) { this.form.controls[key].updateValueAndValidity(); }

  valueChanges(key) { return this.form.controls[key].valueChanges; }
}
