<div class="container">
    <div *ngIf="false===this.getLoginAuth()" class="row d-flex justify-content-center">
        <div class="col-md-6 col-md-offset-3" style="margin-bottom: 100%;">
            <h2 style="color: white;">{{'LOGIN.LOGIN' | translate}}</h2>
            <form [formGroup]="form" (ngSubmit)="this.login()">
                <div class="form-group">
                    <label for="username" style="color: white;">{{'LOGIN.USUARIO' | translate}}</label>
                    <input id="username" type="text" pInputText formControlName="username" class="form-control"
                        required />
                    <span *ngIf="this.isControlInvalid('username')" class="help-block">{{'LOGIN.USUARIOERRO' |
                        translate}}</span>
                </div>
                <div class="form-group">
                    <label for="password" style="color: white;">{{'LOGIN.PASSWORD' | translate}}</label>
                    <input id="password" type="password" pInputText formControlName="password" class="form-control"
                        required />
                    <span *ngIf="this.isControlInvalid('password')" class="help-block">{{'LOGIN.SENHAERRO' |
                        translate}}</span>
                </div>
                <div class="form-actions">
                    <button type="submit" class="btn btn-primary">{{'LOGIN.LOGAR' | translate}}</button>
                </div>
            </form>
        </div>
    </div>
</div>
