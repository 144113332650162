import { LocalStorageService } from './localStorage.service';
import { IAutenticate } from './../pages/login/login.model';
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { Observable, BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { LoginComponent } from "../pages/login/login.component";
import { ActivatedRoute, Router } from '@angular/router';
import { UUID } from 'angular2-uuid';

@Injectable({
  providedIn: "root",
})
export class LoginService {

  public userValue: string = null ;
  public userNameInitials: string = "-";
  private userName: string = "-";
  private userCode: string = "-";
  private userStatusActive: boolean = false;

  public displaySelectionModal: boolean = false;

  private static languageSelected: BehaviorSubject<string> = new BehaviorSubject<string>(
    "pt"
    );
  private static datePickerLanguages = {
    en: {
      firstDayOfWeek: 0,
      dayNames: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      monthNamesShort: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      today: "Today",
      clear: "Clear",
      dateFormat: "mm/dd/yy",
      hourFormat: 12,
    },
    pt: {
      firstDayOfWeek: 0,
      dayNames: [
        "Domingo",
        "Segunda-Feira",
        "Terça-Feira",
        "Quarta-Feira",
        "Quinta-Feira",
        "Sexta-Feira",
        "Sábado",
      ],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
      monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      today: "Hoje",
      clear: "Limpar",
      dateFormat: "dd/mm/yy",
      hourFormat: 24,
    },
  };


  constructor(
    public translate: TranslateService,
    public http: HttpClient,
    public localStorageService: LocalStorageService,
    public router: Router,
    public route: ActivatedRoute,
  ) {
    this.setSelectedLanguage(LoginService.getSelectedLanguageDefault);
  }

  get GetUserActive(): boolean {
    return this.userStatusActive;
  }

  get GetLanguageSelected(): Observable<string> {
    return LoginService.languageSelected;
  }

  getLanguageSelectedValue() {
    return LoginService.languageSelected.value;
  }

  static get getLanguages() {
    return environment.languages;
  }

  static get getSelectedLanguageDefault() {
    let language = localStorage.getItem("languageSelected");
    if (!language) language = "pt";
    return language;
  }

  static get getSelectedLanguageExtension() {
    return environment.languagesExt[LoginService.getSelectedLanguageDefault];
  }

  logout() {
    this.signout(this.localStorageService.get("token")).then(data => {
      this.localStorageService.set("token", data.token);
      this.localStorageService.remove("user");
      this.userValue = UUID.UUID();
      this.router.navigate(["/login"]);
    }).catch(reason => {
      this.translate.get(["LOGIN.LOGAR_ACTION.ERRO_LOGIN", "LOGIN.LOGAR_ACTION.ERRO_LOGIN_TITLE"], null).subscribe(res => {
      });
    }).finally(()=>{
    });
  }

  changeUnit() {
    this.displaySelectionModal = true;
  }

  setSelectedLanguage(lang: string) {
    localStorage.setItem("languageSelected", lang);
    this.translate.use(lang);
    LoginService.languageSelected.next(lang);
  }

  getLoginUserId() {
    return this.userCode;
  }

  getLoginUserName() {
    let userName = this.localStorageService.get("user");
    if (userName) {
      const userNameSplit = this.userName.replace("_", " ").split(" ");

      if (userNameSplit.length > 1) {
        userName = userNameSplit[0] + " " + userNameSplit[1];
      } else {
        userName = userNameSplit[0];
      }
    }
    return userName;
  }

  getDatePickerLocale() {
    return LoginService.datePickerLanguages[LoginService.getSelectedLanguageDefault];
  }

  isUserAdmin() {
    return false;
  }

  login(controller :LoginComponent) {
    const login = controller.getControlValue('username');
    const senha = controller.getControlValue('password');

    controller.setLoginAuth(false);
    this.signin(login, senha).then(data => {
      this.localStorageService.set("token", data.token);
      this.localStorageService.set("user", data.user.username);
      controller.setLoginAuth(true);
      this.userValue = UUID.UUID();
      controller.router.navigate(["/pbi"]);
    }).catch(reason => {
      this.translate.get(["LOGIN.LOGAR_ACTION.ERRO_LOGIN", "LOGIN.LOGAR_ACTION.ERRO_LOGIN_TITLE"], null).subscribe(res => {
        controller.messageService.add({ severity: 'error', summary: res["LOGIN.LOGAR_ACTION.ERRO_LOGIN_TITLE"], detail: res["LOGIN.LOGAR_ACTION.ERRO_LOGIN"] });
      });
    }).finally(()=>{
      controller.stopLoading();
    });
  }

  public signin(login: string, senha: string) {
    return this.http.post<IAutenticate>(
      environment.environmentEndpointUrl()+"api/Auth/Signin",
      {"email": login, "password": senha}
    ).toPromise();
  }

  public signout(token: string) {
    return this.http.post<IAutenticate>(
      environment.environmentEndpointUrl()+"api/Auth/Signout",
      {"token": token}
    ).toPromise();
  }
}
