import { ISupplier } from './../../home/home-supplier/home-supplier.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-suppliers',
  templateUrl: './card-suppliers.component.html',
  styleUrls: ['./card-suppliers.component.scss']
})
export class CardSuppliersComponent implements OnInit {
  @Input() supplier: ISupplier;

  constructor() { }

  ngOnInit(): void {
  }

  srcImage(base64:string):string{
    return `data:image/png;base64,${base64}`;
  }

}
