import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ISeguidores } from "./seguidores.model";


@Injectable({
    providedIn: 'root'
  })

export class ServiceSeguidores{
 constructor (private https: HttpClient){}

  getSegue() {
    return this.https.get<ISeguidores>(environment.environmentEndpointUrl() + "api/Home/followers"
    ).toPromise();
  }

}

