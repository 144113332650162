import { Component, OnDestroy, OnInit } from "@angular/core";
import { BasePageFormComponent } from "../../pages/base-page/base-page-form.component";
import { FormBuilder, Validators } from '@angular/forms'
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { LoginService } from "src/app/service/login.service";
import { ActivatedRoute, Router, ɵangular_packages_router_router_n } from "@angular/router";
import { MessageService } from 'primeng/api';

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.css"],
  })

  export class LoginComponent
  extends BasePageFormComponent
  implements OnInit, OnDestroy {

    loginAuth = false;

    constructor(
      public router: Router,
      public route: ActivatedRoute,
      public loginService: LoginService,
      public messageService: MessageService,
      public translate: TranslateService,
      public modal: NgbModal,
      public formBuilder: FormBuilder
      ) {
        super(router, route, messageService, translate, modal, formBuilder);
        this.loginAuth = false;
      }

      public onInit() {
        this.setFormBuild({
          username: [null,Validators.required],
          password: [null,Validators.required],
        });
        this.loginAuth = false;
    }

    ngOnDestroy(): void {}

    setLoginAuth(valor: boolean) {
      this.loginAuth = valor;
    }

    getLoginAuth(){
      return this.loginAuth;
    }

    login(): void {
      if (this.form.invalid)
        return;

      this.loginService.login(this);
    }
  }
