import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { IEsgCards, IFiltros } from "./esg.model";

@Injectable({
    providedIn: 'root'
  })
  export class EsgService {

    constructor(public http: HttpClient){}

    public getCards(startIndex, endIndex, esgIdFilter:Set<string  >) {
      return this.http.get<IEsgCards>(environment.environmentEndpointUrl()+"api/Home/esg",
        {
          params: {
          startIndex: startIndex,
          endIndex: endIndex,
          esgs: [...esgIdFilter]
        }
      }).toPromise();
    }

    public getInfoFilters() {
      return this.http.get<IFiltros>(environment.environmentEndpointUrl()+"api/Home/esg-filter").toPromise();
    }
  }

