<div class="multi-select" (click)="show = !show">
  <ul>
    <li>{{ clear() ? ("PROOF_CONCEPT.ALL_PILLARS" | translate) : showSelected() }}</li>
    <li *ngIf="!allSelected()" class="hide showing" [class.show]="show" (click)="selectAll()">{{("PROOF_CONCEPT.SELECT_ALL" | translate)}}</li>
    <li *ngIf="allSelected()"class="hide showing" [class.show]="show" (click)="deselectAll()">{{("PROOF_CONCEPT.CLEAR_SELECTEDS" | translate)}}</li>
    <li class="hide showing" [class.show]="show" *ngFor="let d of data" (click)="d.selected = !d.selected; sendPillars()">
      {{ d.descricao }}
      <i *ngIf="d.selected" class="pi pi-check"></i>
    </li>
  </ul>
</div>
