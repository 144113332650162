import { ICountry } from './../home/home-subscribed/home-subscribed.model';
import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tooltip-map',
  templateUrl: './tooltip-map.component.html',
  styleUrls: ['./tooltip-map.component.css'],
})

export class TolltipMap implements OnInit, OnChanges {
  @Input() botaoSelecionado = '';
  @Input() paisSelecionado: ICountry = undefined;

  constructor(
    public translate: TranslateService,
    ) {}

  ngOnChanges() {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }
}
