import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent {
  @ViewChild('modalLoading', { static: false })
  set myInput(input: ElementRef | undefined) {
    if (input) { input.nativeElement.focus(); }
  }

  constructor() { }
}
