import { environment } from './../../../../environments/environment.prod';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-base-page-routing-message',
  templateUrl: './base-page-routing-message.component.html',
  styleUrls: ['./base-page-routing-message.component.css']
})
export class BasePageRoutingMessageComponent implements OnInit {

  constructor(protected router: Router) { }

  public urlRoute: string = "...";
  private classView = "green-card";
  protected textView = "";
  protected hasLoading: boolean = false;
  ngOnInit(): void { environment.routeBackTranslate = ''; }

  protected getUrlRoute() {
    this.urlRoute = this.router.url;
  }

  getClassView() {
    return this.classView;
  }

  setClassView(classes: string) {
    this.classView += ` ${classes}`
  }

  getTextView() {
    return this.textView;
  }

  getHasLoading() {
    return this.hasLoading;
  }
}
