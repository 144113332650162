<div id="cookies-bar" class="cookies-bar" *ngIf="viewCookies">
  <h3 class="title">{{"COOKIES.USES_COOKIE" | translate}}</h3>
  <div class="content"><p>{{"COOKIES.SAVE_DATA" | translate}}</p></div>
  <div class="buttons">
    <a  class=" btn" data-toggle="modal" (click)="modalCookies = true">{{"COOKIES.PRIVACY" | translate}}</a>
      <a class="btn-accept-all btn btn-success" (click)="acceptCookies()">{{"COOKIES.ACCEPTED" | translate}}</a>
  </div>
</div>

<div class="content-background elementToFadeInModal" *ngIf="modalCookies">
  <div [class.show]="modalCookies" class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content pb-3">
        <div class="modal-header">
          <h4 class="modal-title">{{"COOKIES.PRIVACY" | translate}}</h4>
          <span (click)="modalCookies = false">x</span>
        </div>
        <div class="modal-body pl-0 pt-5">
          <div class="content"><p>{{"COOKIES.DATA_PROTECTION" | translate}} <a href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709.htm" rel="noopener noreferrer" target="_blank">{{"COOKIES.LAW" | translate}}</a>{{"COOKIES.DATE" | translate}}</p>

            <p>{{"COOKIES.POLICY" | translate}} 04/10/2021.</p>

            <h2 color="">{{"COOKIES.PERSON_DATA" | translate}}</h2>

            <h2>{{"COOKIES.WHO_WE_ARE" | translate}}</h2>

            <p>{{"COOKIES.CORPORATE_NAME" | translate}}</p>

            <p>CNPJ: 19.759.554/0001-03</p>

            <p>{{"COOKIES.FANTASY_NAME" | translate}}</p>

            <p>{{"COOKIES.ADDRESS" | translate}} - {{"COOKIES.FLOOR" | translate}} &nbsp;30130-174&nbsp;</p>

            <p>{{"COOKIES.WEBSITE" | translate}} <a href="https://www.mininghub.com.br">www.mininghub.com.br</a>
              <a href="https://www.divera.com.br/"></a></p>

            <h2 color="">{{"COOKIES.ACCESSING" | translate}}</h2>

            <p>{{"COOKIES.ACCESS_OUR_SITE" | translate}},&nbsp;{{"COOKIES.COLLECT_DATA" | translate}}&nbsp;{{"COOKIES.INFORMATION" | translate}}</p>

            <ul>
              <li>{{"COOKIES.DATE_TIME" | translate}}</li>
              <li>{{"COOKIES.NAME_PAGE" | translate}}</li>
              <li>{{"COOKIES.IP_ADDRESS" | translate}}</li>
              <li>{{"COOKIES.DEVICE" | translate}}</li>
              <li>{{"COOKIES.ID" | translate}}</li>
              <li>{{"COOKIES.BROWSER_TYPE" | translate}}</li>
              <li>{{"COOKIES.OPERATING_SYSTEM" | translate}}</li>
            </ul>

            <p>{{"COOKIES.PARAGRAPH_DATA" | translate}}&nbsp;{{"COOKIES.PARAGRAPH_DATA_1" | translate}}</p>

            <h2 color="">{{"COOKIES.MONITORING" | translate}}</h2>

            <p>{{"COOKIES.PARAGRAPH_GOOGLE_ANALYTIC" | translate}}<a href="https://www.jusbrasil.com.br/topicos/200399417/artigo-7-da-lei-n-13709-de-14-de-agosto-de-2018" rel="noopener noreferrer" target="_blank">{{"COOKIES.PARAGRAPH_GOOGLE_ANALYTIC_1" | translate}}</a>.{{"COOKIES.PARAGRAPH_GOOGLE_ANALYTIC_2" | translate}}</p>

            <p>{{"COOKIES.INFORMATIONS_COOKIE" | translate}}</p>

            <ul>
              <li>{{"COOKIES.INFORMATION_BROWSER" | translate}}</li>
              <li>{{"COOKIES.INFORMATION_OPARATIONS" | translate}}</li>
              <li>{{"COOKIES.INFORMATION_URL" | translate}}</li>
              <li>{{"COOKIES.INFORMATION_HOST" | translate}}</li>
              <li>{{"COOKIES.INFORMATION_SERVER" | translate}}</li>
            </ul>

            <p>{{"COOKIES.SESSIONS" | translate}}&nbsp;{{"COOKIES.SESSIONS1" | translate}}</p>

            <p>{{"COOKIES.FIND_INFORMATIONS" | translate}}</p>

            <p><a href="https://marketingplatform.google.com/about/analytics/terms/br/" rel="noopener noreferrer" target="_blank">https://marketingplatform.google.com/about/analytics/terms/br/</a></p>

            <p><a href="https://policies.google.com/?hl=pt-BR" rel="noopener noreferrer" target="_blank">https://policies.google.com/?hl=pt-BR</a></p>

            <p>{{"COOKIES.COLLECTION_OF_DATA" | translate}}</p>

            <h2 color="">Cookies</h2>

            <h2 color="">{{"COOKIES.WHAT_ARE_COOKIE" | translate}}</h2>

            <p>{{"COOKIES.VISITING" | translate}}&nbsp;{{"COOKIES.VISITING1" | translate}}&nbsp;{{"COOKIES.VISITING2" | translate}}</p>

            <p>{{"COOKIES.CLASSIFICATIONS_WEBSITE" | translate}}</p>

            <h2 color="">{{"COOKIES.OWN_COOKIES" | translate}}</h2>

            <p>{{"COOKIES.WE_DIRECTLY" | translate}}&nbsp;{{"COOKIES.ACCESS_CODE" | translate}}</p>

            <h2 color="">{{"COOKIES.THIRD_PARTY" | translate}}</h2>

            <p>{{"COOKIES.THESE_TYPE" | translate}}&nbsp;{{"COOKIES.THESE_TYPE_INFORMATION" | translate}}&nbsp;{{"COOKIES.ADVERTISING" | translate}}</p>

            <p>{{"COOKIES.COOKIES_ARE_USED" | translate}}&nbsp;{{"COOKIES.COOKIES_COMPANIES" | translate}}</p>

            <p>{{"COOKIES.WE_USE_COOKIES" | translate}}&nbsp;{{"COOKIES.CERTAIN_COOKIES" | translate}}&nbsp;(<a href="https://www.jusbrasil.com.br/topicos/200399417/artigo-7-da-lei-n-13709-de-14-de-agosto-de-2018" rel="noopener noreferrer" target="_blank">{{"COOKIES.ITEM_LGPD" | translate}}</a>){{"COOKIES.RESPECTIVE_LEGAL" | translate}}</p>

            <p>{{"COOKIES.OBVIOUSLY" | translate}},&nbsp;{{"COOKIES.CONFIGURE_BROWSER" | translate}}&nbsp;{{"COOKIES.HARD_DISK" | translate}}</p>

            <h2 color="">{{"COOKIES.DISABLE_COOKIE" | translate}}</h2>

            <ul>
              <li>{{"COOKIES.EXPLORER" | translate}}

                <ul>
                  <li>{{"COOKIES.SELECT_OPTION" | translate}}</li>
                  <li>{{"COOKIES.PRIVACE" | translate}}</li>
                  <li>{{"COOKIES.ACCEPTED_REJECT" | translate}}</li>
                  <li>{{"COOKIES.CONFIRM" | translate}}</li>
                </ul>
              </li>
              <li>{{"COOKIES.FIREFOX" | translate}}

                <ul>
                  <li>{{"COOKIES.FIREFOX_TOOLS" | translate}}</li>
                  <li>{{"COOKIES.FIREFOX_PRIVACE" | translate}}</li>
                  <li>{{"COOKIES.FIREFOX_MENU" | translate}}</li>
                  <li>{{"COOKIES.FIREFOX_ACCEPTED" | translate}}</li>
                  <li>{{"COOKIES.FIREFOX_CONFIRM" | translate}}</li>
                </ul>
              </li>
              <li>{{"COOKIES.CHROME" | translate}}

                <ul>
                  <li>{{"COOKIES.CHROME_BROWSER" | translate}}</li>
                  <li>{{"COOKIES.CHROME_SETTINGS" | translate}}</li>
                  <li>{{"COOKIES.CHROME_PRIVACE" | translate}}</li>
                  <li>{{"COOKIES.CHROME_CLICK" | translate}}</li>
                  <li>{{"COOKIES.CHROME_GENERAL_SETTINGS" | translate}}</li>
                </ul>
              </li>
              <li>{{"COOKIES.SAFARI" | translate}}

                <ul>
                  <li>{{"COOKIES.SAFARI_SETTINGS" | translate}}</li>
                  <li>{{"COOKIES.SAFARI_PRIVATE" | translate}}</li>
                </ul>
              </li>
            </ul>

            <p>{{"COOKIES.OBSERVE" | translate}}&nbsp;{{"COOKIES.USING_OUR_COOKIE" | translate}}</p>

            <h2 color="">{{"COOKIES.COMMENTS" | translate}}</h2>

            <p>{{"COOKIES.COMMENTS_SITE" | translate}}&nbsp;{{"COOKIES.COLLECT_THE_DATA" | translate}}&nbsp;{{"COOKIES.BROWSER_DATA" | translate}}</p>

            <p>{{"COOKIES.HASH" | translate}} <a href="https://automattic.com/privacy/" rel="noopener noreferrer" target="_blank">https://automattic.com/privacy/</a>{{"COOKIES.COMMENT_APPROVED" | translate}}</p>

            <h2 color="">{{"COOKIES.MEDIA" | translate}}</h2>

            <p>{{"COOKIES.UPLOAD" | translate}}&nbsp;{{"COOKIES.LOCATION_DATA" | translate}}&nbsp;(EXIF GPS){{"COOKIES.VISITORS" | translate}}</p>

            <h2 color="">{{"COOKIES.CONTACT_FORMS" | translate}}</h2>

            <p>{{"COOKIES.DATA_SUBMITTED" | translate}}&nbsp;{{"COOKIES.AUTHORIZES_CONTACT" | translate}}&nbsp;{{"COOKIES.INFORMATIONS_SENT" | translate}}</p>

            <ul>
              <li>{{"COOKIES.NAME" | translate}}</li>
              <li>Email</li>
              <li>{{"COOKIES.PHONE" | translate}}</li>
              <li>WhatsApp</li>
            </ul>

            <p>{{"COOKIES.MARKETING_PURPOSE" | translate}}&nbsp;{{"COOKIES.OTHER_COMPANIE" | translate}}&nbsp;{{"COOKIES.PARTNERS" | translate}}</p>

            <h2 color="">Cookies</h2>

            <p>{{"COOKIES.SAVE_YOUR_NAME" | translate}}&nbsp;{{"COOKIES.FILL_DATA" | translate}}&nbsp;{{"COOKIES.THESE_COOKIE" | translate}}</p>

            <p>{{"COOKIES.ACCOUNT_AND_ACCESS" | translate}}&nbsp;{{"COOKIES.CLOSE_BROWSER" | translate}}</p>

            <p>{{"COOKIES.LOG_ACCOUNT" | translate}}&nbsp;{{"COOKIES.CREATE_COOKIE" | translate}}&nbsp;{{"COOKIES.DISPLAY_CHOICE" | translate}}</p>

            <p>{{"COOKIES.EDIT_OR_PUBLISH" | translate}}&nbsp;{{"COOKIES.PERSONAL_DATA" | translate}}&nbsp;{{"COOKIES.POST" | translate}}</p>

            <h2 color="">{{"COOKIES.EMBEDDED_MEDIA" | translate}}</h2>

            <p>{{"COOKIES.ARTICLES_SITE" | translate}}&nbsp;{{"COOKIES.CONTENT_MEDIA" | translate}}&nbsp;{{"COOKIES.CONTENT" | translate}}</p>

            <p>{{"COOKIES.THESE_SITE" | translate}}&nbsp;{{"COOKIES.LOGGED" | translate}}</p>

            <h2 color="">{{"COOKIES.ANALYSIS" | translate}}</h2>

            <h2 color="">{{"COOKIES.SHARE_YOUR_DATA" | translate}}</h2>

            <p>{{"COOKIES.PERFORMANCE_ANALYTICS" | translate}}&nbsp;{{"COOKIES.INCLUDING_LOCATION" | translate}}</p>

            <p>{{"COOKIES.SELL_OR_TRANSFER" | translate}}&nbsp;{{"COOKIES.LISTED_BELOW" | translate}}</p>

            <ul>
              <li>{{"COOKIES.ADVERTISERS" | translate}}</li>
              <li>{{"COOKIES.OUR_PARTNERS" | translate}}</li>
              <li>{{"COOKIES.STATISTICS" | translate}}</li>
            </ul>

            <h2 color="">{{"COOKIES.KEEP_YOUR_DATA" | translate}}</h2>

            <p>{{"COOKIES.YOUR_PERSONAL_DATA" | translate}}&nbsp;{{"COOKIES.COMPETENT_AUTHORITIES" | translate}}</p>

            <p>{{"COOKIES.PERIOD_FOR_PERSONAL_DATA" | translate}}&nbsp;{{"COOKIES.CONSIDER_THE_AMOUNT" | translate}}&nbsp;{{"COOKIES.ANAUTHORIZED" | translate}}</p>

            <p>{{"COOKIES.PRODUCTS_AND_SERVICES" | translate}}&nbsp;{{"COOKIES.KEEP_THIS_ACOUNT" | translate}}&nbsp;{{"COOKIES.DELETED" | translate}}</p>

            <h2 color="">{{"COOKIES.SECURITY_WORKS" | translate}}</h2>

            <p>{{"COOKIES.ACCOUNT_IN_PROTECTED" | translate}}</p>

            <p>{{"COOKIES.DEVELOPMENT_PRATICES" | translate}}&nbsp;{{"COOKIES.HOST_OUR_SITE" | translate}}&nbsp;{{"COOKIES.BACKUP" | translate}}</p>

            <p>{{"COOKIES.PROTECT_THE_PRIVACY" | translate}}&nbsp;{{"COOKIES.UNAUTHORIZED_ACCOUNT" | translate}}&nbsp;{{"COOKIES.AT_ANY_TIME" | translate}}</p>

            <h2 color="">{{"COOKIES.YOUR_RIGHTS" | translate}}</h2>

            <p>{{"COOKIES.OBTAIN_INFORMATIONS" | translate}} <a href="mailto:juridico@cuidarassistencia.com.br">juridico@cuidarassistencia.com.br</a>.&nbsp;{{"COOKIES.REQUEST_DELETION" | translate}}&nbsp;{{"COOKIES.NATIONAL_DATA" | translate}}(<a href="https://www.gov.br/anpd/pt-br" rel="noopener noreferrer" target="_blank">ANPD</a>), {{"COOKIES.AS_PER" | translate}} <a href="https://www.jusbrasil.com.br/topicos/213446883/artigo-55j-da-lei-n-13709-de-14-de-agosto-de-2018" rel="noopener noreferrer" target="_blank">{{"COOKIES.ITEM" | translate}}</a> {{"COOKIES.FOUND_SECTION" | translate}}</p>

            <h2 color="">{{"COOKIES.CONSENTS" | translate}}</h2></div>
        </div>
      </div>
    </div>
  </div>
</div>

