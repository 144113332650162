import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePageRoutingMessageComponent } from '../base-page/base-page-routing-message/base-page-routing-message.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-access-error',
  templateUrl: './access-error.component.html',
  styleUrls: ['../base-page/base-page-routing-message/base-page-routing-message.component.css', './access-error.component.css']

})
export class AccessErrorComponent extends BasePageRoutingMessageComponent implements OnInit {

  @Input() dataType: string;

  constructor(private route: ActivatedRoute, protected router: Router) {
    super(router);
  }

  ngOnInit(): void {
    if (!this.dataType) {
      this.route
        .data
        .subscribe(data => {
          if (data && data.errorType) {
            this.setTextViewData(data.errorType);
          }
        });
    }
    else{
      this.setTextViewData(this.dataType);
    }
  }

  setTextViewData(dataType) {
    if (dataType == "ACCESS-DENIED") {
      this.textView = "PERMISSAO_IAM";
    } else if (dataType == "ACCESS-ERROR") {
      this.textView = "UNEXPECTED_IAM";
    } else if (dataType == "ACCESS-NO-PERMISSION") {
      this.textView = "ACCESS_NO_PERMISSION";
    }
  }
}
