import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(public http: HttpClient) { }

  get apiEndpointUrl() {
    return environment.environmentEndpointUrl() + "API/";
  }

  protected obterComPaginacao(endpoint: string, pagina: number, termoPesquisa: string, pageSize: number, sortColumn: string, sortDirection: string, id?: number, paramExtra?: any) {
    return this.get(`${this.apiEndpointUrl}${endpoint}/paginacao${id ? `/${id}` : ''}?termoPesquisa=${termoPesquisa}&pagina=${pagina}&registrosPorPagina=${pageSize}&colunaOrdenacao=${sortColumn}&direcaoOrdenacao=${sortDirection}${paramExtra ? `&paramExtra=${paramExtra}` : ''}`);
  }

  protected get<T>(endpoint: string) {
    return this.http.get<T>(`${this.apiEndpointUrl}${endpoint}`).toPromise();
  }

  protected getOfType<T>(endpoint: string) {
    return this.http.get<T>(`${this.apiEndpointUrl}${endpoint}`).toPromise();
  }

  protected getArrayOfType<T>(endpoint: string) {
    return this.http.get<T[]>(`${this.apiEndpointUrl}${endpoint}`).toPromise();
  }

  protected post(endpoint: string, body) {
    return this.http.post(`${this.apiEndpointUrl}${endpoint}`, body).toPromise();
  }

  protected postOfType<T>(endpoint: string, body) {
    return this.http.post<T>(`${this.apiEndpointUrl}${endpoint}`, body).toPromise();
  }

  protected postArrayOfType<T>(endpoint: string, body) {
    return this.http.post<T[]>(`${this.apiEndpointUrl}${endpoint}`, body).toPromise();
  }

  protected put(endpoint: string, body) {
    return this.http.put(`${this.apiEndpointUrl}${endpoint}`, body).toPromise();
  }

  protected deleteBody(endpoint: string, body) {
    return this.http.request('delete', `${this.apiEndpointUrl}${endpoint}`, { body }).toPromise();
  }

  protected deleteById(endpoint: string, id) {
    return this.http.delete(`${this.apiEndpointUrl}${endpoint}${id}`).toPromise();
  }
}
