import { IPowerBiConfig } from './pbi-embed.model';
import { PbiEmbedService } from './pbi-embed.service';
import { AfterViewInit, ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import * as pbi from 'powerbi-client';


@Component({
  selector: 'app-pbi-embed',
  templateUrl: './pbi-embed.component.html',
  styleUrls: ['./pbi-embed.component.css']
})
export class PbiEmbedComponent implements AfterViewInit {
  report?: pbi.Embed;
  @ViewChild('reportContainer', { static: false }) reportContainer?: ElementRef;

  pbiConfig: IPowerBiConfig;

  constructor(private pbiService: PbiEmbedService){}

  ngAfterViewInit(): void {
    const reportPbi = document.getElementById('report-pbi');
    const navHeight = document.getElementsByTagName("nav")[0]?.clientHeight || 580;
    const reportPbiHeight = (document.body.parentElement.clientHeight - navHeight - 15) + "px";
    const reportPbiWidth = (document.body.clientWidth) + "px";
    reportPbi.style.setProperty('height', reportPbiHeight);
    reportPbi.style.setProperty('width', reportPbiWidth);
    this.pbiService.getConfig().then(data => {
      this.pbiConfig = data;
      this.showReport();
    });
  }

  showReport() {
    let settings: pbi.IEmbedSettings = {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
    };

    let config: pbi.IEmbedConfiguration = {
      type: 'report',
      tokenType: pbi.models.TokenType.Aad,
      accessToken: this.pbiConfig.access_token,
      embedUrl: this.pbiConfig.reportBaseURL + this.pbiConfig.reportId + '&groupId=' + this.pbiConfig.groupId,
      filters: [],
      settings: settings
    };

    let reportContainer = this.reportContainer ? this.reportContainer.nativeElement : null;
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    this.report = powerbi.embed(reportContainer, config);
    this.report.off("loaded");

    this.report.on("error", (e) => {
      console.log("Error", e);
    });
  }
}
