import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/service/base.service';
import { environment } from 'src/environments/environment';
import { IPowerBiConfig } from './pbi-embed.model';


@Injectable({
  providedIn: 'root'
})
export class PbiEmbedService extends BaseService {

  constructor(public http: HttpClient) { super(http); }

  public getConfig() {
    return this.http.get<IPowerBiConfig>(environment.environmentEndpointUrl() + "api/PowerBi/config"
    ).toPromise();
  }
}
