import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EnumMethods } from '../models/enums/enum-methods';
import { PermissionEnum } from '../models/enums/permission-enum.model';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuardService implements CanActivate {

  constructor(private router: Router, private loginService: LoginService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
    try {
      if (null !== this.loginService.userValue)
        return true;

      let boundPermission = '';
      let triedPermission = route.data.privilegie;
      boundPermission = Object.keys(PermissionEnum).find(key => PermissionEnum[key] == triedPermission);
      if (boundPermission && boundPermission != '') {
        return true;
      }
    } catch (err) {}

    this.router.navigate(["access-no-permission"]);
    return false;
  }

}
