import { Router } from '@angular/router';
import { Component, Input, OnChanges, OnInit, Output, EventEmitter, SimpleChanges, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { ISunburstData } from '../desafios/desafios.model';

declare var require: any;
// let highchartsTreemap = require('highcharts/modules/treemap')
let sunburst = require('highcharts/modules/sunburst')

// highchartsTreemap(Highcharts);
sunburst(Highcharts);


@Component({
  selector: 'app-sunburst-graph-conexoes',
  templateUrl: './sunburst-graph-conexoes.component.html',
  styleUrls: ['./sunburst-graph-conexoes.component.css']
})
export class SunburstGraphConexoesComponent implements OnInit, OnChanges  {
  @Input() programa : string = '';
  @Input() sunburst : ISunburstData;
  @Input() sunburstHeight : number;
  @Output() sunburstEmitter = new EventEmitter<boolean>();

  public options: any;

  highcharts = Highcharts;
  ngOnChanges(changes: SimpleChanges) {
    if(this.sunburst?.data?.length > 0) {
      this.createChart();
      let titles : HTMLElement[] = this.ref.nativeElement.getElementsByTagName('title');
      Array.from(titles).forEach(function(item, i){
        item.remove();
      })
    }
  }

  constructor(
    public translate: TranslateService,
    public route: Router,
    private ref: ElementRef
  ) {

  }

  ngOnInit() {
  }

  sunburstClick(e){
    if(Number(e.point.id[0]) >= 3){
      this.setStartupData(e.point);
      localStorage.setItem("connectionTitle", e.point.connectionTitle)
      localStorage.setItem("connectionDescription", e.point.connectionDescription)
      this.sunburstEmitter.emit(true)
    }
  }

  setStartupData(e){
    const organizacao = {
      organizacaoID: e.organizaoID,
      conexaoID: e.conexaoID,
      pocID: e.pocID,
      contratoID: e.contratoID
    }

    localStorage.setItem("organizacao", JSON.stringify(organizacao))
  }

  getTextTranslated(keyword: string) {
  let response = ""
    this.translate.get(keyword).subscribe(res => {
      response = res;
    });
    return response;
  }

  createChart(): void {
    this.options = {
      chart: {
        height: this.sunburstHeight,
        backgroundColor: 'rgb(0,0,0,0)',
      },
      plotOptions: {
        sunburst: {
          borderColor: 'white',
          borderWidth: 1,
          states: {
            hover: {
              enabled: true,
              brightness: -0.1,
              opacity: 1
            }
          },
          drillUpButton: {
            position: {
              x: -40,
              y: 40
            }
          }
        }
      },
      colors: ['transparent', '#ffce9d', '#ffffc0', '#c0ffff', '#c0ffff', '#c0ffff', '#ffce9d', '#c0ffff', '#c0ffff', '#c0ffff', '#ffce9d', '#c0ffff', '#c0ffff', '#c0ffff', '#ffce9d', '#c0ffff', '#c0ffff', '#c0ffff', '#ffce9d', '#c0ffff', '#c0ffff', '#c0ffff', '#ffffc0', '#c0ffff', '#c0ffff', '#c0ffff', '#ffffc0', '#c0ffff', '#c0ffff', '#c0ffff', '#ffffc0', '#c0ffff', '#c0ffff', '#c0ffff', '#ffffc0', '#c0ffff', '#c0ffff', '#c0ffff', '#ffffc0', '#c0ffff', '#c0ffff', '#c0ffff', '#ffffc0', '#c0ffff', '#c0ffff', '#c0ffff', '#ffffc0', '#c0ffff', '#c0ffff', '#c0ffff', '#ffffc0', '#c0ffff', '#c0ffff', '#c0ffff', '#ffffc0', '#c0ffff', '#c0ffff', '#c0ffff', '#ffffc0', '#c0ffff', '#c0ffff', '#c0ffff', '#ffffc0', '#c0ffff', '#c0ffff'],
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      series: [{
        type: 'sunburst',
        data: this.sunburst.data,
        events: {
          click: (e) => this.sunburstClick(e)
        },
        allowDrillToNode: true,
        cursor: 'pointer',
        dataLabels: {
          format: '{point.name}',
          rotationMode: 'circular',
        },
        levels: [{
          level: 1,
          levelIsConstant: false,
          levelSize: {
            unit: 'percentage',
            value: 18
          },
        }, {
          level: 2,
          levelSize: {
            unit: 'percentage',
            value: 12
          },
          colorByPoint: true,
        },
        {
          level: 3,
          levelSize: {
            unit: 'percentage',
            value: 45,
          }
        }, {
          level: 4,
        }]
      }],
      tooltip: {
        headerFormat: '',
        pointFormat: '<tspan style="font-size: 1rem;">{point.name} {point.quantidadeConexoes}</tspan>'
      },
    };

    Highcharts.chart('container-conexoes', this.options);
  }
}
