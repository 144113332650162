import { GlobalFilterService } from './../../../service/global-filter/global-filter.service';
import { MenuTranslateService } from '../../../pages/nav-menu/components/menu-translate/menu-translate.service';
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { environment } from "src/environments/environment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from 'primeng/api';
import { StatusEnum } from "src/app/models/enums/status-enum.model";
import { IContractedFiltred, IContractedFilter, IContractedFilterParam } from "./home-contratadas.model";
import { ProgramsContractedsService } from "./home-contratadas.service";
import { BasePageComponent } from 'src/app/pages/base-page/base-page.component';
import { IHome } from '../home-subscribed/home-subscribed.model';
import { IGlobalFilter } from 'src/app/service/global-filter/global-filter.model';

@Component({
  selector: 'home-contratadas',
  templateUrl: './home-contratadas.component.html',
  styleUrls: ['./home-contratadas.component.scss']
})
export class HomeContratadasComponent extends BasePageComponent {
  @Input() menuData;
  @Input() globalFilter: IGlobalFilter = {program: 'M-Start', cycle: 0};
  @Input() botaoSelecionado = 'startups_contracteds';
  @Output() selectedPage = new EventEmitter<string>();
  @Output() selectedSession = new EventEmitter<string>();

  userActive: StatusEnum;
  userSub: any;
  langSub: any;
  rowGroupMetadata: any;
  exibir: number = 1;
  showPrograms: boolean = false;
  showCycles: boolean = false;
  showContracts: boolean = false;
  data: IHome;

  ciclosFiltro: number[];

  contracteds: IContractedFiltred[] = [];
  contractedFilter: IContractedFilter;
  selectedStartup: string;
  selectedProgram: string;
  selectedCycle: string | number;
  borderOne = false;
  borderTwo = false;

  filterParam: IContractedFilterParam = { program: '', cycle: '', contractor: { name: '', count: 0 } };

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public translate: TranslateService,
    public modal: NgbModal,
    private programsContractedsService: ProgramsContractedsService,
    public menuTranslateService: MenuTranslateService,
    private globalFilterService: GlobalFilterService
  ) {
    super(router, route, messageService, translate, modal);
  }

  onInit(): void {
    this.filterParam.program = this.globalFilter.program;
    this.filterParam.cycle = this.globalFilter.cycle === 0? "" : this.globalFilter.cycle.toString();
    environment.routeBackTranslate = "";
    environment.previousScreenTitleTranslate = "";
    environment.titleTranslate = 'HOME.TITLE';
    this.startLoading();
    this.programsContractedsService.getContractedsFilters().then(data => {
      this.contractedFilter = data;
      this.setCycleFilterFullList();
    }).finally(() => {
      this.stopLoading();
    });

    this.setContractedData()
    this.getContractedsByLanguage();
    this.globalFilterService.getObserve().subscribe((data) => {
      const tmpCycle = this.selectedCycle;
      const tmpProgram = this.selectedProgram;
      this.filterParam.program = data.program != '-'? data.program: 'M-Start';
      this.filterParam.cycle = data.cycle === 0? "" : data.cycle.toString();
      this.selectedCycle = Number(data.cycle) === 0? "" : data.cycle;
      this.selectedProgram = this.filterParam.program;
      if(tmpCycle !== data.cycle || tmpProgram !== this.filterParam.program) {
        this.applyFilter();
      }
    });
  }

  setContractedData() {
    this.startLoading();
    this.programsContractedsService.getContracteds(this.filterParam, true)
      .then(data => {
        this.contracteds = data;
      }).finally(() => {
        this.stopLoading();
      });
  }

  getContractedsByLanguage() {
    this.menuTranslateService.getLanguage().subscribe((data: string) => {
      this.setContractedData();
    });
  }

  applyFilter(): void {
    if (this.filterParam.program != '') {
      this.ciclosFiltro = this.contractedFilter.program_cycles
        .filter(p => p.program.toLocaleLowerCase() === this.filterParam.program.toLocaleLowerCase())![0].cycles
        .sort((a, b) => a - b).reverse();
    } else {
      this.setCycleFilterFullList();
    }
    this.setGlobalFilter();
    this.programsContractedsService.getContracteds(this.filterParam, false)
      .then(data => {
        this.contracteds = data;
      });
  }

  clickButton(button: string) {
    this.botaoSelecionado = button;
  }
  selectComponent(component: string, session: string) {
    this.selectedPage.emit(component);
    this.selectedSession.emit(session);
  }

  getButtonClass(button: string): string {
    return this.botaoSelecionado === button ? "numberTela1" : "numberTela";
  }

  colorButton(value) {
    if (value === 1) {
      this.borderOne = true;
    } else {
      this.borderTwo = true;
    }
  }

  colorLeave(value) {
    if (value === 1) {
      this.borderOne = false;
    } else {
      this.borderTwo = false;
    }
  }

  protected setCycleFilterFullList() {
    let setCycles = new Set<number>();
    this.contractedFilter.program_cycles.forEach(item => {
      item.cycles.forEach(element => {
        setCycles.add(element);
      });
    });
    this.ciclosFiltro = [...setCycles].sort((a, b) => a - b).reverse();
  }

  setGlobalFilter() {
    if(this.filterParam.program !== '' && this.filterParam.cycle) {
      const newFilter: IGlobalFilter = { program: this.filterParam.program, cycle: Number(this.filterParam.cycle)};
      this.globalFilterService.setFilter(newFilter);
    }
  }

  resetCycle(program: string) {
    if(this.selectedProgram === program) {
      this.filterParam.cycle = "";
      this.selectedCycle = "";
    }
  }

}
