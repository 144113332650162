import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from 'src/app/service/base.service';
import { ModalConexoesModel } from './modal-conexoes.model';

@Injectable({
  providedIn: 'root'
})
export class ModalConexoesService extends BaseService {

  constructor(public http: HttpClient) { super(http); }

  getData() {
    const param = JSON.parse(localStorage.getItem("organizacao"));

    return this.http.get<ModalConexoesModel>(environment.environmentEndpointUrl()+"api/Home/sunburst-conexoes-detalhamento", {
      params: {
        organizacaoID: param.organizacaoID,
        conexaoID: param.conexaoID,
        pocID: param.pocID,
        contratoID: param.contratoID
      }
    })
    .toPromise();
  }

}
