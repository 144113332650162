<div class="app">

  <div class="main">
    <div class="top-row">

      <div *ngIf="this.getTitle()">
        <h5 class="cor-font-padrao-escura font-caecilia-bold m-0">
          <span *ngIf="this.getPreviousTitle()">{{this.getPreviousTitle() | translate}} > </span>
          {{ this.getTitle() | translate }}
        </h5>
      </div>
      <div style="flex-grow: 1; height: 5rem; padding:0px; z-index: 20;" class="bg-back container-fluid d-flex flex-row justify-content-center">
        <header id="navHeader" class="header bg">
          <div class="row align-middle">
            <div class="col-2 shrink" style="text-align: center; padding-bottom:.8rem;">
              <img style=" height: 36px; width: 191px; margin-left: 2rem;" src="assets/images/thumb__0_0_0_0_auto.png"
                alt="Mining Hub"  [title]="'Mining Hub v'+ version">
            </div>
            <nav class="col-9 text-center">
              <ul class="menu" style="flex-wrap: nowrap;">
                <li id="menu-item-7076"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5308">
                  <a href="https://www.mininghub.com.br/" aria-current="page">{{"HOME.HOME_SUBSCRIBED.HOME" | translate}}</a>
                </li>
                <li id="menu-item-5006" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5006">
                  <a href="https://www.mininghub.com.br/quem-somos/">{{"HOME.HOME_SUBSCRIBED.WHO_WE_ARE" | translate}}</a></li>
                <li id="menu-item-5384"
                  class="menu-item menu-item-type-post_type menu-item-object-programas menu-item-5384">
                  <a href="https://www.mininghub.com.br/programas/m-start/">{{"HOME.HOME_SUBSCRIBED.PROGRAMS" | translate}}</a>
                </li>
                <li id="menu-item-5009" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5009">
                  <a href="https://www.mhnumbers.com.br/mininghub/#/">{{"HOME.HOME_SUBSCRIBED.MH_IN_NUMBERS" | translate}}</a></li>
                <li id="menu-item-5012" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5012">
                  <a href="https://www.mininghub.com.br/blog/">{{"HOME.HOME_SUBSCRIBED.BLOG" | translate}}</a></li>
                <li id="menu-item-5308" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-5308">
                  <a href="https://www.mininghub.com.br/agenda/">{{"HOME.HOME_SUBSCRIBED.SCHEDULE" | translate}}</a></li>
                <!-- <li id="menu-item-5308" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-7073 current_page_item menu-item-7076"><a
                  routerLink="/home">Portal</a></li> -->
              </ul>
               <!-- <a href="#home" id="nav-icon" class="bt_menu" title="Menu"> <span class="bgBlack"></span> <span
                  class="bgBlack"></span> <span class="bgBlack"></span> </a> -->
              <!--  <ul class="listLang">
               <li class="lang-pb active"><a href="https://www.mininghub.com.br/pb/" hreflang="pb"
                    title="Português do Brasil (pb)"><span style="text-transform: uppercase;">pb</span></a></li>
                <li class="lang-en"><a href="https://www.mininghub.com.br/en/" hreflang="en" title="English (en)"><span
                      style="text-transform: uppercase;">en</span></a></li>
              </ul><a href="https://drive.mininghub.com.br/#/login" class="btMembers" title="Área de membros" target="_blank">
                <img src="./Home - Mining Hub_files/icon-members.png" alt="Área de membros" title="Área de membros"> </a> -->
            </nav>
            <app-menu-translate class="col-1 menu-translate" style="text-align: end;z-index:999; padding-right:.8rem;" [isBodyContainer]="true"></app-menu-translate>
            <!-- <div *ngIf="this.loginService.getLoginUserName()" id="logoutButton" class="cor-font font-caecilia-bold sair-btn"
              (click)="this.loginService.logout()">
              <span>{{ 'LOGOUT.TITLE' | translate }}</span>
            </div> -->
            <!-- <app-user  style="z-index:999;"></app-user> -->
            </div>
          </header>
        </div>
      <div style="flex-grow: 1; height: 100%;"></div>

    </div>

    <h5 *ngIf="this.getTitle()" class="text-center titulo cor-font-padrao-escura font-caecilia-bold">
      <span *ngIf="this.getPreviousTitle()">{{this.getPreviousTitle() | translate}} >
      </span>{{ this.getTitle() | translate }}
    </h5>

    <div class="content">
      <h5 *ngIf="this.getRouteBack()" class="link-voltar">
        <a *ngIf="this.getRouteBackIsString()" class="cor-font-padrao" [routerLink]="['/' + this.getRouteBack()]">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>{{ getPreviousButtonTitle() | translate }}
        </a>

        <a *ngIf="!this.getRouteBackIsString()" class="cor-font-padrao" style="cursor: pointer;"
          (click)="this.execRouteBack()">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>{{ getPreviousButtonTitle() | translate }}
        </a>
      </h5>
      <main id="main" class="flex-grow-1  flex-row justify-content-center" [ngClass]="mainClass">
        <router-outlet style="justify-content: center;"></router-outlet>
      </main>
      <footer>
        <div>
        </div>
      </footer>

    </div>
  </div>

  <p-toast [showTransformOptions]="'translateY(34px)'" class="custom-toast"></p-toast>
</div>
