import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoginService } from '../login.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MsgBoxComponent } from 'src/app/components/msg-box/msg-box.component';

@Injectable()
export class LogoutResolver implements Resolve<any> {

  constructor(private loginService: LoginService, public modal: NgbModal, private router: Router) {
    // Constructor
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const modalRef = this.modal.open(MsgBoxComponent, { centered: true });
    modalRef.componentInstance.mensagem = "LOGOUT.MESSAGE";
    modalRef.componentInstance.titulo = "LOGOUT.TITLE";

    let queryParams = {
      queryParams: { language: null }
    };
    this.loginService.GetLanguageSelected.subscribe(lang => {
      if (lang) {
        queryParams.queryParams.language = lang;
      }
    });

    modalRef.result.then((result) => {

      if ((result === 'sim')) {
        //this.loginService.logout();
        this.router.navigate(['/home'], queryParams);
      }
    }, (dismiss) => {});

    return true;
  }

}
