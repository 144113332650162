<div *ngIf="this.loginService.getLoginUserName() != '-'" ngbDropdown class="user-container"
  style="display: flex;flex-direction: column;">
  <button ngbDropdownToggle class="btn user cor-font-padrao font-caecilia-bold">
    <div class="user">
      <div class="round">{{this.loginService.userNameInitials}}</div>
      <div class="login">
        <div class="name font-caecilia">{{this.loginService.getLoginUserName()}}</div>
      </div>
    </div>
  </button>
  <div ngbDropdownMenu style="min-width: auto; right: 0;">
    <button *ngIf="this.loginService.GetUserActive" id="changeUnitButton" class="cor-font-padrao font-caecilia-bold" ngbDropdownItem
      (click)="this.loginService.changeUnit()">
      <div>
        <span>{{ 'APP.CHANGE_UNIT' | translate }}</span>
        <span><em class="fa fa-exchange"></em></span>
      </div>
    </button>
    <button id="logoutButton" class="cor-font-padrao font-caecilia-bold" ngbDropdownItem
      (click)="this.loginService.logout()">
      <div>
        <span>{{ 'LOGOUT.TITLE' | translate }}</span>
        <img alt="" [src]="'assets/images/icones/menu/logout.png'" />
      </div>
    </button>
  </div>
</div>
<button *ngIf="this.loginService.getLoginUserName() == '-'" id="logoutButton" class="cor-font font-caecilia-bold" style="width: 4rem; padding-top: 1.5rem;"
  ngbDropdownItem (click)="this.loginService.logout()">
  <span>{{ 'LOGOUT.TITLE' | translate }}</span>
</button>
