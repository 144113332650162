import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css']
})
export class CookiesComponent implements OnInit {
  viewCookies: boolean = false;
  modalCookies: boolean;

  constructor() { }

  ngOnInit(): void {
    const accepted = localStorage.getItem("mininghub_cookies");
    this.viewCookies = (accepted == "accepted") ? false : true;
  }

  acceptCookies(){
    localStorage.setItem("mininghub_cookies","accepted");
    this.viewCookies = false;
  }

}
