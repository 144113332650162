import { IHome } from './home-subscribed.model';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })

export class ServiceHomeData{

    constructor (private http: HttpClient){}

    getData() {
      return this.http.get<IHome>(environment.environmentEndpointUrl()+'api/home'
      ).toPromise();
    }
}
