<div class="card">
  <div class="card-effect ">
    <span></span><span></span>
    
      <div class="associated-inside-container">
        <div class="associated-image-container">
          <div style="margin: auto;">
            <img *ngIf="supplier?.img" class="associated-image" [src]="srcImage(supplier?.img) || ''"/>
          </div>
        </div>
        <div class="associated-text-container">
          <div class="associated-detail-text">
            <b class="associated-label-name">{{supplier?.name}}</b>
          </div>
        </div>
        <div>
          <a [href]="supplier?.website" target="_blank">
          <b class="associated-site-label">{{"HOME.HOME_SUPPLIER.SUPPLIER_WEBSITE" | translate}}</b></a>
        </div>
      </div>
 
  </div>
</div>
