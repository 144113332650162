import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IHomeMiner } from './home-miner.model';
import { BaseService } from 'src/app/service/base.service';
import { environment } from 'src/environments/environment';
import { IHome } from '../home-subscribed/home-subscribed.model';


@Injectable({
  providedIn: 'root'
})
export class HomeMinerService extends BaseService {

  constructor(public http: HttpClient) { super(http); }

  public getMiners() {
    return this.http.get<IHome>(environment.environmentEndpointUrl()+"api/Home/home-miner"
    ).toPromise();
  }
}
