import { GlobalFilterService } from './../../service/global-filter/global-filter.service';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { BasePageComponent } from 'src/app/pages/base-page/base-page.component';
import { MenuTranslateService } from 'src/app/pages/nav-menu/components/menu-translate/menu-translate.service';
import { IDesafioFilter } from '../prova-conceito/prova.model';
import { ICicloData, ICicloFiltros } from './ciclo.model';
import { CicleService } from './ciclo.service';
import { IGlobalFilter } from 'src/app/service/global-filter/global-filter.model';
import { LocalStorageService } from 'src/app/service/localStorage.service';

@Component({
  selector: 'ciclo',
  templateUrl: './ciclo.component.html',
  styleUrls: ['./ciclo.component.css']
})
export class CicloComponent extends BasePageComponent {
  @Input() desafioParam : IDesafioFilter;

  loading = true;
  data: ICicloData;
  filter: ICicloFiltros[];
  showPrograms: boolean = false;
  showCycles:boolean = false;
  ciclos: number[];
  programaSelecionado: string;
  cicloSelecionado: number;
  arrays = [];
  programs: any[];
  cycles:any [];

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public translate: TranslateService,
    public modal: NgbModal,
    private cicleSevice: CicleService,
    public menuTranslateService: MenuTranslateService,
    private globalFilterService: GlobalFilterService,
    private localStorageService: LocalStorageService
  ){
    super(router, route, messageService, translate, modal);
  }

  onInit(): void {
    this.startLoading();
    this.cicleSevice.getCicloFiltro().then(filter => {
      this.programaSelecionado = this.desafioParam?.program || "M-Start";
      this.filter = filter;
      this.programs = filter;
      this.filterCycles(this.programaSelecionado);
      this.selectCycle(this.cycles[0]);
    }).finally(()=> {
      this.delay(1000);
    });

    this.menuTranslateService.getLanguage().subscribe(()=> {
      this.setCicloData(this.programaSelecionado, this.cicloSelecionado);
      this.getDateFormat();
    });

    this.globalFilterService.getObserve().subscribe((data) => {
      let prg = data.program != '-'? data.program: 'M-Start';
      if(data.cycle === 0 || this.programaSelecionado?.toLowerCase() !== prg?.toLowerCase()) {
        this.filterCycles(prg);
        Array.from(document.getElementById("cicloSelectCycles").children).forEach(element => {
          element.classList.remove("selected");
          this.translate.get("CYCLE.CYCLE_M").subscribe((text) => {
            if(element.innerHTML === text + " " + this.cicloSelecionado) {
              element.classList.add("selected");
            }
          });
        });
      } else  if(document.getElementById("cicloSelectCycles")) {
        Array.from(document.getElementById("cicloSelectCycles").children).forEach(element => {
          element.classList.remove("selected");
          this.translate.get("CYCLE.CYCLE_M").subscribe((text) => {
            if(element.innerHTML === text + " " + data.cycle) {
              element.classList.add("selected");
            }
          });
        });
        this.cicloSelecionado = data.cycle;
      }
      this.programaSelecionado = prg;
      this.getData(this.programaSelecionado, this.cicloSelecionado);
    });

  }

  delay(ms: number) {
    return new Promise( resolve => {
      setTimeout(resolve, ms)
    }).finally(()=> {
      this.loading = false;
      this.stopLoading();
    });
  }

  setCicloData(program, cycle){
      this.cicleSevice.getCicloFiltro().then(d => {
        this.filter = d;
        this.getData(program, cycle);
    });
  }

  getData(program, cycle) {
      this.cicleSevice.getCiclo(program, cycle)
      .then(data => {
        this.data = data;
    });
  }

  selectProgram(program: string){
    this.programaSelecionado = program;
    this.filterCycles(program);
    this.showPrograms = false;
    this.setGlobalFilter();
  }

  selectCycle(cycle: number){
    this.cicloSelecionado = cycle;
    this.setGlobalFilter();
  }

  filterCycles(program: string){
    let prg = program != '-'? program : 'M-Start';
    this.cycles = this.programs
                          .find(p => p.program.toLowerCase() == prg.toLowerCase())["cycles"]
                          .sort((a, b) => a - b)
                          .reverse();
    this.cicloSelecionado = this.cycles[0];
  }

  getDateFormat() {
    return this.localStorageService.get("languageSelected") == "pt" ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
  }

  protected setGlobalFilter(){
    const newFilter: IGlobalFilter = {program: this.programaSelecionado, cycle: this.cicloSelecionado};
    this.globalFilterService.setFilter(newFilter);
  }

}
