import { ModalConexoesModel, Connection } from './modal-conexoes.model';
import { ModalConexoesService } from './modal-conexoes.service';
import { Component, Input, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'modal-conexoes',
  templateUrl: './modal-conexoes.component.html',
  styleUrls: ['./modal-conexoes.component.css']
})
export class ModalConexoesComponent implements OnInit {
  @Input() openModal: boolean = false;
  @Output() modalEmitter = new EventEmitter<boolean>();
  data: ModalConexoesModel;
  selectedConnection: Connection;

  constructor(
    private modalConexoesService: ModalConexoesService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {

  }

  ngOnChanges() {
    (this.openModal) ? this.setBodyOverflowY('hidden') : this.setBodyOverflowY('auto');
    this.get();
  }

  get(){
    if(this.openModal){
      this.modalConexoesService.getData()
      .then((data: ModalConexoesModel) => {
        this.data = data;
        this.data.connectionDescription = (localStorage.getItem("connectionDescription") != "undefined") ? localStorage.getItem("connectionDescription") : '';
        this.data.connectionTitle = (localStorage.getItem("connectionTitle") != "undefined") ? localStorage.getItem("connectionTitle") : '';
        this.selectedConnection = this.data.connections[0];
      })
    }
  }

  close(){
    this.modalEmitter.emit(false);
  }

  selectConnection(connection: Connection){
    this.selectedConnection = connection;
  }

  setBodyOverflowY(v: string){
    this.document.body.style.overflowY = v;
  }

}
