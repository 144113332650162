import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { FormControl } from "@angular/forms";
import { BasePageComponent } from "../base-page/base-page.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from 'primeng/api';
import { StatusEnum } from "src/app/models/enums/status-enum.model";
import { IDesafioFilter } from "src/app/components/prova-conceito/prova.model";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent
  extends BasePageComponent
  implements OnInit, OnDestroy {

  @HostListener('window:scroll', ['$event']) onWindowScroll(e) {
    const elements = [
      'home-component',
      'desafios-component',
      'prova-conceito',
      'programas-ciclo-mstart',
      'esg-component',
      'conexoes-component',
    ]

    const y = e.target['scrollingElement'].scrollTop;
    this.setActiveAnchor(y);
  }

  filter = new FormControl("");
  loading = false;
  userActive: StatusEnum;
  userSub: any;
  langSub: any;
  rowGroupMetadata: any;
  exibir: number = 1;
  data = null;
  borderOne = false;
  borderTwo = false;
  esgExpanded = false;
  desafioExpanded: boolean = false;
  desafioParam = {} as IDesafioFilter;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public translate: TranslateService,
    public modal: NgbModal,
  ) {
    super(router, route, messageService, translate, modal);
  }

  public onInit() { }

  ngOnInit(): void {

    super.ngOnInit();
    environment.routeBackTranslate = "";
    environment.previousScreenTitleTranslate = "";

    environment.titleTranslate = 'HOME.TITLE';

  }

  ngOnDestroy(): void {
    if (this.userSub != undefined) this.userSub.unsubscribe();
    if (this.langSub != undefined) this.langSub.unsubscribe();
  }

  submit() {
    this.loading = true;
  }

  colorButton(value) {
    if (value === 1) {
      this.borderOne = true;
    } else {
      this.borderTwo = true;
    }
  }

  colorLeave(value) {
    if (value === 1) {
      this.borderOne = false;
    } else {
      this.borderTwo = false;
    }
  }

  scroll(line: string, pag: number) {
    const componentRect = document.getElementById(line).getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();
    window.scroll(0,componentRect.top - bodyRect.top - 60);

    setTimeout(() => {
      this.exibir = pag;
    }, 300);

  }

  setIsShowEsg(value){
    this.esgExpanded = value;
  }

  setIsShowDesafio(value: boolean){
    this.desafioExpanded = value;
  }

  setActiveAnchor(y: number){
    if (y <= 580) {
      this.exibir = 1;
    } else if (y <= 1161){
      this.exibir = 2;
    } else if ((!this.desafioExpanded && y <= 1750) || (this.desafioExpanded && y <= 2400)){
      this.exibir = 3;
    }else if ((!this.desafioExpanded && y <= 2367) || (this.desafioExpanded && y <= 3000)){
      this.exibir = 4;
    }else if (
      (!this.desafioExpanded && y <= 2700) || (this.desafioExpanded && y <= 3600) ||
      (!this.esgExpanded && y <= 2900) || (this.esgExpanded && y <= 4200)
    ){
      this.exibir = 5;
    }else{
      this.exibir = 6;
    }
  }
}
