import { InterceptorModule } from './service/interceptor/interceptor.module';
import { SunburstGraphConexoesComponent } from './components/sunburst-graph-conexoes/sunburst-graph-conexoes.component';
import { ConexoesComponent } from './components/conexoes/conexoes.component';
import { PbiPageComponent } from './pages/pbi-page/pbi-page.component';
import { PbiEmbedComponent } from './components/pbi-embed/pbi-embed.component';
import { HomeSupplierComponent } from './components/home/home-supplier/home-supplier.component';
import { CardContractedComponent } from './components/cards/card-contracted/card-contracted.component';
import { CardMinersComponent } from './components/cards/card-miners/card-miners.component';
import { TelaSeguidoresComponent } from './components/seguidores/seguidores.component';
import { HomeMainComponent } from './components/home/home-main.component';
import { HomeSubscribedComponent } from './components/home/home-subscribed/home-subscribed.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, DecimalPipe, DatePipe, registerLocaleData, TitleCasePipe } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { NavMenuComponent } from './pages/nav-menu/nav-menu.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MsgBoxComponent } from './components/msg-box/msg-box.component';
import { MenuTranslateComponent } from './pages/nav-menu/components/menu-translate/menu-translate.component';
import { UserComponent } from './pages/nav-menu/components/user/user.component';
import { DatepickerI18nComponent, DateParserFormatterComponent } from './components/date-pickers/datepicker-i18n/datepicker-i18n.component';
import 'src/assets/prototypes/date-custom.prototype';
import ptBr from '@angular/common/locales/pt';
import esEs from '@angular/common/locales/es';
import enUs from '@angular/common/locales/en';
import { DateUtcPipe } from 'src/assets/pipes/date-utc.pipe';
import { InputTextModule } from 'primeng/inputtext';
import { WrongRouteComponent } from './pages/wrong-route/wrong-route.component';
import { BasePageRoutingMessageComponent } from './pages/base-page/base-page-routing-message/base-page-routing-message.component';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { AccessErrorComponent } from './pages/access-error/access-error.component';
import { LogoutResolver } from './service/resolver/logout.resolve';
import { KeyFilterModule } from 'primeng/keyfilter';
import { AccordionModule } from 'primeng/accordion';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { DatePickerComponent } from './components/date-pickers/default-date-picker/date-picker.component';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { TreeTableModule } from 'primeng/treetable';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { ConfigService } from './service/config.service';
import { TableModule } from 'primeng/table';
import { DividerModule } from 'primeng/divider';
import { ToolbarModule } from 'primeng/toolbar';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService, MessageService } from "primeng/api";
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HomeMinerComponent } from './components/home/home-miner/home-miner.component';
import { TolltipMap } from './components/tooltip-map/tooltip-map.component';
import { EventosComponent } from './components/eventos/eventos.component';
import { DesafiosComponent } from './components/desafios/desafios.component';
import { TruncatePipe } from 'src/assets/pipes/truncate.pipe';
import { OutputGraphComponent } from './components/output-graph/output-graph.component';
import { CicloComponent } from './components/ciclo/ciclo.component';
import { ProvaConceitoComponent } from './components/prova-conceito/prova.component';
import { BarGraphComponent } from './components/bar-graph/bar-graph.component';
import { SunburstGraphComponent } from './components/sunburst-graph/sunburst-graph.component';
import { CardSuppliersComponent } from './components/cards/card-suppliers/card-suppliers.component';
import { ModalConexoesComponent } from './components/modal-conexoes/modal-conexoes.component';
import { EsgComponent } from './components/esg/esg.component';
import { ModalImgComponent } from './components/modal-img/modal-img.component';
import { RodapeComponent } from './components/rodape/rodape.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { CardEsgComponent } from './components/cards/card-esg/card-esg.component';
import { NgbActiveModal, NgbDateParserFormatter, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CookiesComponent } from './components/cookies/cookies.component';
import { HomeContratadasComponent } from './components/home/contratadas/home-contratadas.component';


registerLocaleData(ptBr);
registerLocaleData(esEs);
registerLocaleData(enUs);


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

@NgModule({
  imports: [
    ToastModule,
    ConfirmPopupModule,
    DividerModule,
    TableModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularSvgIconModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'pt',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxMaskModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    CardModule,
    ButtonModule,
    InputTextModule,
    KeyFilterModule,
    AccordionModule,
    InputTextareaModule,
    RadioButtonModule,
    CalendarModule,
    CheckboxModule,
    TabViewModule,
    DropdownModule,
    TreeTableModule,
    TooltipModule,
    DialogModule,
    ToolbarModule,
    SidebarModule,
    InterceptorModule,
    InfiniteScrollModule
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    HomeMainComponent,
    HomeSubscribedComponent,
    HomeMinerComponent,
    HomeSupplierComponent,
    PbiEmbedComponent,
    PbiPageComponent,
    LoginComponent,
    NavMenuComponent,
    LoadingComponent,
    MsgBoxComponent,
    MenuTranslateComponent,
    UserComponent,
    DateUtcPipe,
    WrongRouteComponent,
    BasePageRoutingMessageComponent,
    AccessErrorComponent,
    DatePickerComponent,
    TolltipMap,
    TelaSeguidoresComponent,
    EventosComponent,
    DesafiosComponent,
    TruncatePipe,
    OutputGraphComponent,
    SunburstGraphComponent,
    BarGraphComponent,
    CicloComponent,
    ProvaConceitoComponent,
    HomeContratadasComponent,
    CardMinersComponent,
    CardContractedComponent,
    CardSuppliersComponent,
    ConexoesComponent,
    SunburstGraphConexoesComponent,
    ModalConexoesComponent,
    EsgComponent,
    ModalImgComponent,
    RodapeComponent,
    MultiSelectComponent,
    CardEsgComponent,
    CookiesComponent
  ],
  providers: [
    ConfirmationService,
    MessageService,
    DecimalPipe,
    DatePipe,
    TitleCasePipe,
    { provide: NgbDatepickerI18n, useClass: DatepickerI18nComponent },
    { provide: NgbDateParserFormatter, useClass: DateParserFormatterComponent },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    ConfigService,
    ConfigService.InitProvider,
    LogoutResolver,
    NgbActiveModal
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
